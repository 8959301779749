import { DefaultLoader } from "common/Loader"
import React, { useState } from "react"
import { Document, Page, pdfjs } from "react-pdf"
import 'react-pdf/dist/Page/AnnotationLayer.css'
import 'react-pdf/dist/Page/TextLayer.css'

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    'pdfjs-dist/build/pdf.worker.min.mjs',
    import.meta.url,
  ).toString()

const PDFConverter = ({ pdf, zoom=1, setSize, setMaxPages, pageNum, setErrorText }) => {
  const onLoadSuccess = async (pdfObj) => {
    try { 
      const page = await pdfObj.getPage(pageNum)
      const bigness = {
        height: page?._pageInfo?.view[3],
        width: page?._pageInfo?.view[2]
      }
      setSize(bigness)
      setMaxPages(pdfObj?._pdfInfo?.numPages)
    } catch (e) {
      console.error('the document failed to load')
    }
  }
  return (
      <div className="doc-wrapper">
          <Document file={ pdf } onLoadSuccess={onLoadSuccess}>
            <Page pageNumber={pageNum} scale={zoom} />
          </Document>
      </div>
  )
}
  
export default PDFConverter