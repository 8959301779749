import {
  getDivisionDataSelector,
  getJobDataSelector,
} from "features/Job/jobSlice";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { getRequirement } from "../utils";
import { VscGithubAction } from "react-icons/vsc";
import { Typography } from "@mui/material";
import styled from "styled-components";
import { first, size, toArray } from "lodash";
import { jobDivisionFileCategoriesSelectors } from "features/Taxonomies/taxonomiesSlice";

const Container = styled.div`
  border: ${({ complete }) =>
    complete ? "1px solid var(--color-green-alt)" : "1px solid var(--color-blue-dark)"};
  border-radius: 3px;
  background-color: var(--color-blue);
  background-image: ${({ complete }) =>
    complete
      ? "linear-gradient(135deg, var(--color-teal-dark) 0%, var(--color-green-alt) 100%)"
      : "linear-gradient(135deg, var(--color-blue) 0%, var(--color-blue-darker) 100%)"};
  box-shadow: rgb(17 51 83 / 4%) 0px 4px 12px 0px;
  margin-bottom: 1.25rem;
  padding: 1.25rem;
  color: white;
  display: flex;
  &.editable{
    cursor: pointer;
  }

  .icon {
    opacity: 0.75;
    font-size: 30px;
    margin-right: 1rem;
    path {
      stroke: white;
    }
  }
`;

const ProgressItem = ({ type, field, categories }) => {
  const job = useSelector(getJobDataSelector);
  const division = useSelector(getDivisionDataSelector);
  const editable = Boolean(division?._processed?.milestones_update_progress);
  const { label, component } = getRequirement(
    type,
    field,
    categories,
    division,
    job
  );

  const item = <Container className={(editable ? 'editable' : '')}>
                  <div className="icon">
                    <VscGithubAction />
                  </div>
                  <div className="content">
                    <Typography variant="subtitle1">Up Next</Typography>
                    <Typography variant="h6">{label}</Typography>
                  </div>
                </Container>;

  if(!editable){
    return item;
  }

  return React.cloneElement(component, {
    children: (
      item
    ),
  });
};

const getNextAction = (milestones) => {
  for (let i = 0; i < milestones.reqs.time_fields.length; i++) {
    const req = milestones.reqs.time_fields[i];
    const status =
      milestones.progress && milestones.progress?.time_fields?.[req]
        ? size(milestones.progress?.time_fields?.[req]) > 0
        : null;

    if (!status) {
      return { type: "time_fields", field: req };
    }
  }

  for (let i = 0; i < milestones.reqs.personnel_fields.length; i++) {
    const req = milestones.reqs.personnel_fields[i];
    const status =
      milestones.progress && milestones.progress?.personnel_fields?.[req]
        ? size(milestones.progress?.personnel_fields?.[req]) > 0
        : null;

    if (!status) {
      return { type: "personnel_fields", field: req };
    }
  }

  if (
    milestones.progress.require_damage_area &&
    milestones.progress?.damage_areas
    && milestones.progress.damage_areas === 0
  ) {
    return { type: "require_damage_area", field: "require_damage_area" };
  }

  milestones.reqs.files.forEach((req) => {
    const status =
      milestones.progress && milestones.progress?.files?.[req]
        ? size(milestones.progress?.files?.[req]) > 0
        : null;

    if (!status) {
      return { type: "files", field: req };
    }
  });

  if (
    milestones.progress.photos.before_completed <
    milestones.progress.photos.before_required
  ) {
    return { type: "photos", field: "before" };
  }

  if (
    milestones.progress.photos.during_completed <
    milestones.progress.photos.during_required
  ) {
    return { type: "photos", field: "during" };
  }

  if (
    milestones.progress.photos.after_completed <
    milestones.progress.photos.after_required
  ) {
    return { type: "photos", field: "after" };
  }

  return null;
};

const MilestonesNextAction = (props) => {
  const progress = useSelector((state) => state.jobProgress.data);
  const loading = useSelector((state) => state.jobProgress.loading);
  const division = useSelector(getDivisionDataSelector);
  const categories = useSelector(jobDivisionFileCategoriesSelectors.selectAll);

  const milestones = useMemo(() => {
    const obj = division?._processed?.milestone_requirements;
    const prog = progress?.milestone_progress?.data;

    const reqs = first(toArray(obj));
    let time_fields = [];
    let personnel_fields = [];
    let require_damage_area = false;
    let photos = {};
    let files = [];

    if (reqs) {
      photos = reqs.photos;
      time_fields = reqs.time_fields;
      personnel_fields = reqs.personnel_fields;
      require_damage_area = reqs?.require_damage_area;
      files = reqs.file_categories;
    }

    const data = {
      reqs: {
        time_fields,
        personnel_fields,
        require_damage_area,
        photos,
        files,
      },
      progress: {
        time_fields: [],
        personnel_fields: [],
        require_damage_area: false,
        photos: {},
        files: [],
      },
    };

    if (prog) {
      if (prog.time_fields) {
        data.progress.time_fields = prog.time_fields;
      }

      if (prog.personnel_fields) {
        data.progress.personnel_fields = prog.personnel_fields;
      }

      if (prog?.require_damage_area) {
        data.progress.require_damage_area = prog.require_damage_area;
      }

      if (prog.photos) {
        data.progress.photos = prog.photos;
      }

      if (prog.file_categories) {
        data.progress.files = prog.file_categories;
      }
    }

    return data;
  }, [division, progress]);

  const nextAction = getNextAction(milestones);

  if (loading)
    return (
      <Container>
        <div className="icon">
          <VscGithubAction />
        </div>
        <div className="content">
          <Typography variant="subtitle1">Loading...</Typography>
          <Typography variant="h6">Loading What's Next</Typography>
        </div>
      </Container>
    );

  return (
    <div>
      {nextAction ? (
        <ProgressItem
          type={nextAction.type}
          field={nextAction.field}
          categories={categories}
        />
      ) : (
        <Container complete>
          <div className="icon">
            <VscGithubAction />
          </div>
          <div className="content">
            <Typography variant="subtitle1">Up Next</Typography>
            <Typography variant="h6">Complete</Typography>
          </div>
        </Container>
      )}
    </div>
  );
};

MilestonesNextAction.propTypes = {};

export default MilestonesNextAction;
