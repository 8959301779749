import React, { useEffect, useMemo, useState } from "react";
import { Typography, Grid, DialogActions } from "@mui/material";
import moment from "moment";
import { first } from "lodash";
import { NavigateBefore, Add } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchForms,
  formsSelectors,
  formCategoriesSelectors,
  fetchFormCategories,
  getFormsLoadingSelector,
  fetchForm,
  getFormDataSelector,
  getFormLoadingSelector,
  postFormSubmission,
  getEdits,
} from "features/FormBuilder/formBuilderSlice";
import {
  getDivisionDataSelector,
  getJobDataSelector,
} from "features/Job/jobSlice";
import { size } from "lodash";
import { DefaultLoader } from "common/Loader";
import Empty from "common/Empty";
import { getCustomerDataSelector } from "features/Customer/customerSlice";
import { getMemberDataSelector } from "features/Member/memberSlice";
import { DialogForm } from "common/Dialog";
import Button from "common/Button";
import styled from "styled-components";
import Card from "common/Card";
import { FaFolder, FaFilePdf } from "react-icons/fa";
import CheckboxFormField from "common/Fields/CheckboxFormField";
import DateFormField from "common/Fields/DateFormField";
import DateTimeFormField from "common/Fields/DateTimeFormField";
import SelectFormField from "common/Fields/SelectFormField";
import SignatureFormField from "common/Fields/SignatureFormField";
import TextFormField from "common/Fields/TextFormField";
import TimeFormField from "common/Fields/TimeFormField";
import { replaceHashtags } from "../utils";
import { getLocationDataSelector } from "features/Location/locationSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import Dialog from "common/Dialog";
import FormShare from "../forms/FormShare";
import { createPdfFromEdits } from "features/FormFromPDF/pdfUtils";
import { handleUploadToS3 } from "features/Files/utils";
import { generateTempUUID } from "utility";
import PDFFormCollector from "features/FormFromPDF/PDFFormCollector";

const Styles = styled.div`
  .categories {
    padding: 1.25rem;

    .category {
      cursor: pointer;

      .content {
        display: flex;
        align-items: center;
      }

      .icon {
        padding: 1rem;
        font-size: 2rem;
        color: var(--color-gray-medium);
      }
    }
  }

  .form {
    max-width: 900px;
    margin: 0 auto;
  }
`;

const Signature = ({ id, value }) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        fullWidth
        variant="contained"
        disableElevation
        style={{
          background:
            size(value) > 0 ? "var(--color-green)" : "var(--color-red)",
          color: "white",
        }}
      >
        Digital Signature
      </Button>
      <Dialog
        open={open}
        maxWidth="xs"
        onClose={() => setOpen(false)}
        title="Signature"
        keepMounted
      >
        <div style={{ padding: "1rem" }}>
          <SignatureFormField name={id} value={value} />
        </div>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Done</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const getFormField = (field, division, member, customer, location, value) => {
  const type = field.type.name;

  const label = replaceHashtags(
    division,
    member,
    customer,
    location,
    field.label
  );

  switch (type) {
    case "text":
      if (field.read_only) {
        return <Typography variant="subtitle1">{label}</Typography>;
      }

      return (
        <TextFormField
          fullWidth
          variant="filled"
          size="small"
          name={`${field.id}`}
          label={label}
          margin="normal"
          multiline={field.multiline > 0}
          required={field.required > 0}
        />
      );
    case "paragraph":
      return <Typography variant="subtitle1">{label}</Typography>;
    case "select":
      return (
        <SelectFormField
          fullWidth
          variant="filled"
          size="small"
          margin="normal"
          name={`${field.id}`}
          label={label}
          options={field.options.map((option) => ({
            label: option.label,
            value: option.value,
          }))}
        />
      );
    case "signature":
      return <Signature id={`${field.id}`} value={value} />;
    case "checkbox":
      return (
        <div style={{ margin: "1rem 0" }}>
          <CheckboxFormField name={`${field.id}`} label={label} />
        </div>
      );
    case "date":
      return (
        <DateFormField
          fullWidth
          format="MM/DD/YYYY"
          label={label}
          size="small"
          name={`${field.id}`}
          margin="normal"
          inputVariant="filled"
          KeyboardButtonProps={{
            "aria-label": "change date",
          }}
        />
      );
    case "datetime":
      return (
        <DateTimeFormField
          fullWidth
          label={label}
          margin="normal"
          size="small"
          name={`${field.id}`}
          inputVariant="filled"
          KeyboardButtonProps={{
            "aria-label": "change date",
          }}
        />
      );
    case "time":
      return (
        <TimeFormField
          fullWidth
          label={label}
          margin="normal"
          size="small"
          name={`${field.id}`}
          inputVariant="filled"
          variant="inline"
          KeyboardButtonProps={{
            "aria-label": "change end time",
          }}
        />
      );
    default:
      return null;
  }
};

const Categories = ({ setCategory, setTitle }) => {
  const categories = useSelector(formCategoriesSelectors.selectAll);

  return (
    <div className="categories">
      <Grid container spacing={2}>
        <Grid item xxs={12} sm={6} md={4} lg={3}>
          <div
            className="category"
            onClick={() => {
              setTitle("All");
              setCategory({ id: "all", name: "All" });
            }}
          >
            <Card disablePadding>
              <div className="content">
                <div className="icon">
                  <FaFolder />
                </div>
                <Typography>All</Typography>
              </div>
            </Card>
          </div>
        </Grid>
        {categories.map((category) => (
          <Grid item xxs={12} sm={6} md={4} lg={3}>
            <div
              className="category"
              key={category.id}
              onClick={() => {
                setTitle(category.name);
                setCategory(category);
              }}
            >
              <Card>
                <div className="content">
                  <div className="icon">
                    <FaFolder />
                  </div>
                  <Typography>{category.name}</Typography>
                </div>
              </Card>
            </div>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

const Forms = ({ setCategory, category, setFormId, setTitle }) => {
  const dispatch = useDispatch();
  const loading = useSelector(getFormsLoadingSelector);
  const forms = useSelector(formsSelectors.selectAll);
  const customer = useSelector(getCustomerDataSelector);
  const job = useSelector(getJobDataSelector);
  const member = useSelector(getMemberDataSelector);

  useEffect(() => {
    let promise;
    if (category?.id === "all") {
      promise = dispatch(
        fetchForms({
          customer_id: customer.nid,
          client_id: job.field_phoenix_client.nid,
          member_id: member.nid,
          published: 1,
        })
      );
    } else {
      promise = dispatch(
        fetchForms({
          category: category?.id,
          customer_id: customer.nid,
          client_id: job.field_phoenix_client.nid,
          member_id: member.nid,
          published: 1,
        })
      );
    }

    return () => {
      if (promise) {
        promise.abort();
      }
    };
  }, [dispatch, category, member, job, customer]);

  const clientForms = useMemo(() => {
    return forms.filter((form) => form.access_type.name === "client");
  }, [forms]);

  const providerForms = useMemo(() => {
    return forms.filter((form) => form.access_type.name === "provider");
  }, [forms]);

  return (
    <div className="categories">
      <Button
        button
        onClick={() => {
          setTitle("Form");
          setCategory(null);
        }}
        startIcon={<NavigateBefore />}
        style={{ marginBottom: "2rem" }}
      >
        Back
      </Button>
      {loading ? (
        <DefaultLoader />
      ) : size(forms) > 0 ? (
        <>
          {size(clientForms) > 0 && (
            <>
              <Typography variant="h6" style={{ margin: "2rem 0" }}>
                Client Forms
              </Typography>
              <Grid container spacing={2}>
                {clientForms.map((form) => (
                  <Grid item xxs={12} sm={6} md={4} lg={3}>
                    <div
                      className="category"
                      key={category.id}
                      onClick={() => {
                        setTitle(form.name);
                        setFormId(form.id);
                      }}
                    >
                      <Card>
                        <div className="content">
                          <div className="icon">
                            <FaFilePdf />
                          </div>
                          <Typography>{form.name} {form?.attachment ? (<span className="beta">beta</span>) : ''} </Typography>
                        </div>
                      </Card>
                    </div>
                  </Grid>
                ))}
              </Grid>
            </>
          )}
          {size(providerForms) > 0 && (
            <>
              <Typography variant="h6" style={{ margin: "2rem 0" }}>
                Job Forms
              </Typography>
              <Grid container spacing={2}>
                {providerForms.map((form) => (
                  <Grid item xxs={12} sm={6} md={4} lg={3}>
                    <div
                      className="category"
                      key={category.id}
                      onClick={() => {
                        setTitle(form.name);
                        setFormId(form.id);
                      }}
                    >
                      <Card>
                        <div className="content">
                          <div className="icon">
                            <FaFilePdf />
                          </div>
                          <Typography>{form.name}</Typography>
                        </div>
                      </Card>
                    </div>
                  </Grid>
                ))}
              </Grid>
            </>
          )}
        </>
      ) : (
        <Empty message="No forms found" />
      )}
    </div>
  );
};

const Form = ({ id, setFormId, category, setTitle, values }) => {
  const dispatch = useDispatch();
  const form = useSelector(getFormDataSelector);
  const loading = useSelector(getFormLoadingSelector);
  const division = useSelector(getDivisionDataSelector);
  const member = useSelector(getMemberDataSelector);
  const customer = useSelector(getCustomerDataSelector);
  const location = useSelector(getLocationDataSelector);

  useEffect(() => {
    let promise;

    if (id) {
      promise = dispatch(fetchForm(id));
    }

    return () => {
      if (promise) {
        promise.abort();
      }
    };
  }, [id, dispatch]);

  const fields = useMemo(() => {
    return form?.fields
      ? [...form.fields].sort((a, b) => a.order - b.order)
      : [];
  }, [form]);

  const getDescription = (str) => {
    return replaceHashtags(division, member, customer, location, str);
  };

  return loading ? (
    <DefaultLoader />
  ) : (
    <>
      <div className="form">
        <Button
          button
          onClick={() => {
            setTitle(category.name);
            setFormId(null);
          }}
          startIcon={<NavigateBefore />}
        >
          Back
        </Button>
        { form?.attachment ? (<PDFFormCollector attachment={form.attachment} values={values} fields={fields} />) : (<div style={{ padding: "1.25rem" }}>
          {form.description &&
            getDescription(form.description)
              .split("\n")
              .map((paragraph, i) => (
                <Typography key={i} variant="body1">
                  {paragraph}
                </Typography>
              ))}
          <Grid container spacing={1}>
            {fields.map((field, i) => (
              <Grid
                item
                xxs={field.read_only ? 12 : field.column_size}
                key={field.id}
              >
                <div
                  style={{ margin: field.read_only ? "1rem 0" : "0" }}
                  key={field.id}
                >
                  {getFormField(
                    field,
                    division,
                    member,
                    customer,
                    location,
                    values[field.id]
                  )}
                  {field.description &&
                    getDescription(field.description)
                      .split("\n")
                      .map((paragraph, i) => (
                        <Typography key={i} variant="body1">
                          {paragraph}
                        </Typography>
                      ))}
                </div>
              </Grid>
            ))}
          </Grid>
        </div>)}
      </div>
    </>
  );
};

const FormDropdown = ({ children }) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [category, setCategory] = useState(null);
  const [formId, setFormId] = useState(null);
  const [title, setTitle] = useState("Form");
  const user = useSelector((state) => state.auth.user.data);
  const form = useSelector(getFormDataSelector);
  const division = useSelector(getDivisionDataSelector);
  const member = useSelector(getMemberDataSelector);
  const customer = useSelector(getCustomerDataSelector);
  const location = useSelector(getLocationDataSelector);
  const job = useSelector(getJobDataSelector);
  const categories = useSelector(formCategoriesSelectors.selectAll);
  const token = useSelector(
    (state) => state.auth.user?.data?._processed?.phx_offsite_api_token
  )


  useEffect(() => {
    let promise;
    if (open) {
      promise = dispatch(fetchFormCategories());
    }

    return () => {
      if (promise) {
        promise.abort();
      }
    };
  }, [dispatch, open]);

  useEffect(() => {
    if (size(categories) === 0 && open) {
      setCategory("all");
    }
  }, [categories, open]);

  const getLogo = (type) => {
    switch (type) {
      case "provider":
        return division?._processed?.member_logo;
      case "client":
        return division?._processed?.client_logo;
      case "customer":
        return division?._processed?.customer_logo;
      case "none":
        return null;
      default:
        return null;
    }
  };

  const getAddress = (type) => {
    switch (type) {
      case "provider":
        if (!member.field_street_address) return null;
        return {
          address_line1: member.field_street_address.address_line1,
          address_line2: member.field_street_address.address_line2,
          locality: member.field_street_address.locality,
          administrative_area: member.field_street_address.administrative_area,
          postal_code: member.field_street_address.postal_code,
          country: "US",
        };
      case "client":
        if (!job?.field_phoenix_client?.field_pc_address) return null;
        return {
          address_line1:
            job.field_phoenix_client.field_pc_address.address_line1,
          address_line2:
            job.field_phoenix_client.field_pc_address.address_line2,
          locality: job.field_phoenix_client.field_pc_address.locality,
          administrative_area:
            job.field_phoenix_client.field_pc_address.administrative_area,
          postal_code: job.field_phoenix_client.field_pc_address.postal_code,
          country: "US",
        };
      case "customer":
        if (!customer.field_street_address) return null;
        return {
          address_line1: customer.field_street_address.address_line1,
          address_line2: customer.field_street_address.address_line2,
          locality: customer.field_street_address.locality,
          administrative_area:
            customer.field_street_address.administrative_area,
          postal_code: customer.field_street_address.postal_code,
          country: "US",
        };
      case "none":
        return null;
      default:
        return null;
    }
  };

  const getPhone = (type) => {
    switch (type) {
      case "provider":
        return member.field_phone;
      case "client":
        return job?.field_phoenix_client?.field_pc_phone;
      case "customer":
        return customer.field_phone;
      case "none":
        return null;
      default:
        return null;
    }
  };

  const submitWithAttachment = async (params, attachment) => {
    const res = dispatch(getEdits({ id: params.form_id }))
    const form = await res.unwrap()
    const pdfByteArray = await createPdfFromEdits(attachment, form.edits, params.values)

    const generatedFile = new File([new Blob([new Uint8Array(pdfByteArray)], { type: "application/pdf" })], params.name, { type: "application/pdf" })

    const uuid = generateTempUUID()

    await handleUploadToS3({
      file: generatedFile,
      token,
      setProcessing: null,
      setProgress: null,
      onUploadAction: async (response) => {
        params.attachment = {
          destination: "attachments/",
          thumb: false,
          thumbResize: [300, 300],
          uuid: uuid,
          key: response.key,
          bucket: response.bucket,
          name: params.name,
          content_type: 'application/pdf',
          type: generatedFile.type,
          size: 0,
          src: URL.createObjectURL(generatedFile),
        }
      },
    })

    dispatch(postFormSubmission(params)).unwrap()
  }

  const handleSubmit = async (
    data,
    setSubmitting,
    setMessage,
    handleSuccess,
    setFieldValue,
    setFieldError
  ) => {
    try {
      const values = [];
      for (let id in data) {
        const field = form.fields.find((field) => `${field.id}` === `${id}`);
        values.push({
          field_id: id,
          form_field_type_id: field.type.id,
          label: replaceHashtags(
            division,
            member,
            customer,
            location,
            field.label
          ),
          description: replaceHashtags(
            division,
            member,
            customer,
            location,
            field.description
          ),
          value: replaceHashtags(
            division,
            member,
            customer,
            location,
            data[id]
          ),
        });
      }

      const params = {
        name: replaceHashtags(division, member, customer, location, form.name),
        description: replaceHashtags(
          division,
          member,
          customer,
          location,
          form.description
        ),
        form_id: form.id,
        job_division_id: division.nid,
        user_id: user.uid,
        address: getAddress(form.address),
        phone: getPhone(form.address),
        logo_url: getLogo(form.logo),
        values,
      };
      
      const resultAction = await form?.attachment ? submitWithAttachment(params, form.attachment) : dispatch(postFormSubmission(params));

      unwrapResult(resultAction);

      handleClose();
    } catch (err) {
      if (err?.errors) {
        for (let key in err.errors) {
          const error = err.errors[key];
          const index = key.replace(".value", "").replace("values.", "");
          const field = form.fields[Number(index)];

          setFieldError(
            `${field.id}`,
            error
              .map((e) => e.replace(`The values.${index}.value`, ""))
              .join(" ")
          );
        }
      }

      console.error(err);
    }
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setCategory(null);
    setTitle("form");
    setFormId(null);
    setOpen(false);
  };

  const initialValues = useMemo(() => {
    const values = {};

    if (form.fields) {
      form.fields.forEach((field) => {
        if (field.read_only) {
          values[field.id] = "";
        } else if (
          field.type.name === "date" ||
          field.type.name === "datetime" ||
          field.type.name === "time"
        ) {
          values[field.id] = moment();
        } else if (field.type.name === "select") {
          const firstOption = first(field.options);
          values[field.id] = firstOption?.label ? firstOption.label : "";
        } else if (field.type.name === "checkbox") {
          values[field.id] = false;
        } else {
          values[field.id] = "";
        }
      });
    }

    return values;
  }, [form]);

//  Check Permissions
  if(division?._processed?.job_access_type !== 'admin' && division?._processed?.job_access_type !== 'member'){
    return null;
  }

  return (
    <>
      {children ? (
        React.cloneElement(children, {
          onClick: handleOpen,
        })
      ) : (
        <Button
          color="tertiary"
          size="small"
          variant="contained"
          disableElevation
          onClick={handleOpen}
          startIcon={<Add />}
        >
          Form
        </Button>
      )}
      <DialogForm
        open={open}
        onClose={handleClose}
        title={title}
        maxWidth="sm"
        initialValues={initialValues}
        onSubmit={handleSubmit}
        actions={<FormShare />}
        fillScreen
        disablePadding
        enableReinitialize
      >
        {({ values, errors, isSubmitting, validateField, setFieldValue }) => (
          <Styles>
            {formId ? (
              <div className="form">
                <Form
                  id={formId}
                  setFormId={setFormId}
                  category={category}
                  setTitle={setTitle}
                  values={values}
                />
              </div>
            ) : category ? (
              <Forms
                setFormId={setFormId}
                setCategory={setCategory}
                category={category}
                setTitle={setTitle}
              />
            ) : (
              <Categories setCategory={setCategory} setTitle={setTitle} />
            )}
          </Styles>
        )}
      </DialogForm>
    </>
  );
};

FormDropdown.propTypes = {};

export default FormDropdown;
