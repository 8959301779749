import React, { useEffect, useMemo, useState } from 'react'
import PDFConverter from '../PDFConverter'
import { useDispatch, useSelector } from 'react-redux'
import { getEdits, getFormDataSelector } from 'features/FormBuilder/formBuilderSlice'

import '../FormFromPDF.scss'
import { Field } from 'formik'
import PDFFormField from './PDFFormField'
import { ArrowLeft, ArrowRight } from 'lucide-react'

const PDFFormCollector = ({ attachment, values, fields }) => {
    const dispatch = useDispatch()
    const [size, setSize] = useState()
    const [maxPages, setMaxPages] = useState()
    const [pageNum, setPageNum] = useState(1)
    const [zoom, setZoom] = useState(1)
    const [edits, setEdits] = useState([])

    const zoomOpts = {
        maxZoom: 2,
        zoomIncrement: .25,
        baseZoom: 1
    }

    useEffect(() => {
        dispatch(getEdits({ id: attachment.attachable_id })).then(({ payload }) =>  {
            setEdits(payload.edits.map(edit => ({
                ...edit,
                width: Number(edit.width),
                height: Number(edit.height),
                x: Number(edit.x),
                y: Number(edit.y),
                size: Number(edit.size),
            })))
        })
    }, [attachment.attachable_id, dispatch])

    const turnPage = (direction) => {
        if(direction === 'left' && pageNum > 1)
            setPageNum(pageNum-1)
        if(direction === 'right' && pageNum < maxPages)
            setPageNum(pageNum+1)
    }

    const handleZoom = (direction) => {
        if(direction === 'in')
            zoom < zoomOpts.maxZoom ? setZoom(zoom+zoomOpts.zoomIncrement) : setZoom(zoom)
        if(direction === 'out')
            zoom > zoomOpts.baseZoom ? setZoom(zoom-zoomOpts.zoomIncrement) : setZoom(zoom)
    }

    const form = useSelector(getFormDataSelector)

    const mePdf = useMemo(() => {
        return form?.attachment?.url
    }, [form])

    
    
    return (
        <div className='form-collector-wrapper'>
            <div className="page-controls" style={{ position: 'absolute', top: size?.height+15, margin: '0 auto'}}>
                <ArrowLeft fontSize='large' onClick={() => turnPage('left')}/>
                Page {pageNum} of {maxPages}
                <ArrowRight fontSize='large' onClick={() => turnPage('right')}/>
            </div>
            <div className='canvas-wrapper' style={{ height: size?.height, width: size?.width}}>
                {edits.filter((edit) => edit.page === pageNum).map(edit => (
                    <div className='form-collector-field' style={{ 
                        position: 'absolute', 
                        top: edit.y*zoom, 
                        left: edit.x*zoom, 
                        height: edit.height*Math.sqrt(zoom), 
                        width: edit.width*Math.sqrt(zoom) }} >
                        <Field name={edit.form_field_id}>
                            {({
                                field, // { name, value, onChange, onBlur }
                                form,
                                meta,
                            }) => {
                                const handleChange = (e) => {
                                    form.setFieldValue(edit.form_field_id, e.target.value)
                                }

                                const handleBlur = () => form.setFieldTouched(edit.form_field_id, true)
                                return (
                                    <PDFFormField zoom={zoom} edit={edit} onChange={handleChange} onBlur={handleBlur} />
                                )
                            }}
                        </Field>
                    </div>
                )) }
            </div>
            <PDFConverter pdf={mePdf} zoom={zoom} setSize={setSize} setMaxPages={setMaxPages} pageNum={pageNum} />
        </div>
    )
}

export default PDFFormCollector