import { PDFDocument, StandardFonts, rgb, degrees, grayscale } from 'pdf-lib'

const getStartByAlignment = (edit, font) => {
  if(edit.alignment === 'left')
    return edit.x
  if(edit.alignment === 'right')
    return edit.x+edit.width-font.widthOfTextAtSize(edit.text, edit.size)
  if(edit.alignment === 'center')
    return edit.x+((edit.width-font.widthOfTextAtSize(edit.text, edit.size))/2)
}


export async function createPdfFromEdits(attachment, edits, values) {
  const existingPdfBytes = await fetch(attachment.url).then(res => res.arrayBuffer())
  const pdfDoc = await PDFDocument.load(existingPdfBytes)
  const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica)
  const fontSizeRatio = (14/16)
  const pages = pdfDoc.getPages()
  const convertedEdits = edits.map(edit => {
    return {
      ...edit,
      height: Number(edit.height),
      width: Number(edit.width),
      x: Number(edit.x),
      y: Number(edit.y),
      size: Number(edit.size),
    }
  })
  const { width, height } = pages[0].getSize()

  pages.forEach(async (page, i) => {
    const relevantTabs = convertedEdits.filter(edit => edit.page === i+1)
    relevantTabs.forEach(async edit => {
      const field = values.find(fieldVal => Number(fieldVal.field_id) === edit.form_field_id)
      //fist we'll draw a rectangle whiting out the original square to make space for our entry
      page.drawRectangle({
        x: edit.x,
        y: height-(edit.y+edit.height),
        height: edit.height,
        width: edit.width,
        color: rgb(1, 1, 1),
        rotate: edit?.rotate ? edit.rotate : degrees(0)
      })

      //add the entry to the pdf by the type of edit it is
      if(edit.type === 'text' || edit.type === 'date' || edit.type === 'textbox') {
        page.drawText(field.value, {
          x: getStartByAlignment(edit, helveticaFont),
          y: height-(edit.y+(edit.size*fontSizeRatio)),
          size: edit.size,
          lineHeight: edit.size,
          maxWidth: edit.width,
          font: edit?.font ? edit.font : helveticaFont,
          color: edit?.color ? edit.color : rgb(0, 0, 0),
          rotate: edit?.rotate ? edit.rotate : degrees(0)
        })
      }
      if(edit.type === 'signature') {
        const img = await pdfDoc.embedPng(field.value)
        page.drawImage(img, {
          x: edit.x,
          y: height-(edit.y+edit.height),
          width: edit.width,
          height: edit.height,
        })
      }
    })
  })

  const pdfBytes = await pdfDoc.save()

  return pdfBytes
}

export const fetchAndLoadPdf = async (pdfUrl) => {
  const existingPdfBytes = await fetch(pdfUrl).then(res => res.arrayBuffer())
  console.log('pdfBytes', existingPdfBytes)
  const pdfDoc = await PDFDocument.load(existingPdfBytes)
 
  const pdfBytes = await pdfDoc.save()
  return pdfBytes
}

export const modifyPdfByUri = async (pdfUri, edits = []) => {
  console.log('the pdf url:', pdfUri)
  const existingPdfBytes = await fetch(pdfUri).then(res => res.arrayBuffer())
  const pdfDoc = await PDFDocument.load(existingPdfBytes)
  const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica)
  const pages = pdfDoc.getPages()
  const fontSizeRatio = (14/16)
  const firstPage = pages[0]
  const { width, height } = firstPage.getSize()

  pages.forEach((page, i) => {
    const relevantTabs = edits.filter(edit => edit.page === i+1)
    console.log(relevantTabs)
    relevantTabs.forEach(edit => {
      page.drawRectangle({
        x: edit.x,
        y: height-(edit.y+edit.height),
        height: edit.height,
        width: edit.width,
        color: rgb(1, 1, 1),
        borderWidth: 1,
        borderColor: grayscale(0.5),
        rotate: edit?.rotate ? edit.rotate : degrees(0)
      })
      page.drawText(edit.text, {
        x: getStartByAlignment(edit, helveticaFont),
        y: height-(edit.y+(edit.size*fontSizeRatio)),
        size: edit.size,
        lineHeight: edit.size,
        maxWidth: edit.width,
        font: edit?.font ? edit.font : helveticaFont,
        color: edit?.color ? edit.color : rgb(0, 0, 0),
        rotate: edit?.rotate ? edit.rotate : degrees(0)
      })
    })
  })
  const pdfBytes = await pdfDoc.save()

  return { data: pdfBytes, size: { height: height, width: width }}
}

export const mapEditsToTabs = (edits) => {
  console.log('edits in map', edits)
  return edits.map((edit, id)=> {
    return {
      ...edit,
      isDragging: false,
      id: edit?.id ? edit.id : id
    }
  })
}

export const mapTabsToEdits = (edits) => {
  return edits.map((edit, id) => {
    return {
      ...edit,
      isDragging: false,
      id: edit?.id ? edit.id : id
    }
  })
}