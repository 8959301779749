import React, { useState } from "react";
import { useDispatch, useSelector} from "react-redux";
import {
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import PunchClockIcon from '@mui/icons-material/PunchClock';
import { Pencil, } from "lucide-react";
import { FiCoffee } from "react-icons/fi";
import { TbWalk } from "react-icons/tb";
import { FaFileSignature } from "react-icons/fa";
import { TrashIcon } from "@heroicons/react/outline";
import { DialogForm } from "common/Dialog";
import moment from "moment-timezone";
import constants from "components/constants";
import { TimeFormField, CheckboxFormField } from "common/Fields";
import { getDivisionDataSelector, } from "features/Job/jobSlice";
import { bulkPatchTimeClock } from "../timeClockSlice";

const TimeCardBulkEditAction = ({type, cards, checked, disabled, handleRefresh }) => {
  const dispatch = useDispatch();
  const division = useSelector(getDivisionDataSelector);
  const [open, setOpen] = useState(false);
  const isTimeBased = (type !== 'ignore_signature' && type !== 'delete');

  const handleSubmit = (data, setSubmitting) => {
    setSubmitting(true);

    const params = {
      nids: checked,
      ignore_signature: data.ignore_signature,
      delete: data.delete,
    };
    if(isTimeBased){
      params[type] = moment(data[type]).format(constants.iso8601);
    }

    dispatch(bulkPatchTimeClock({hash: division.field_public_hash, params}))
      .then(() => {
        handleRefresh();
        setSubmitting(false);
        setOpen(false);
      });
  }

  let tip = "";
  let iconClass = '';
  let icon = null;
  let titleText = '';
  let fieldLabel = '';
  let submitText = '';
  const initialValues = {
    ignore_signature: false,
    delete: false,
  };
  if(isTimeBased){
    initialValues[type] = moment();
  }
  let _disabled = disabled;
  const s = cards.length > 1 ? 's' : '';
  const clockedInQty = cards.filter((card) => card.clock_status === 'clocked_in').length;
  switch(type){
    case 'start_time':
      tip = "Change Start Times";
      iconClass = 'shift-start';
      icon = (
        <>
        <PunchClockIcon className="clock" />
        <Pencil className="pencil" />
        </>
      );
      titleText = "Select new start time";
      initialValues.start_time = moment().set("hour", 9).set("minute", 0);
      fieldLabel = "New Start Time";
      submitText = `Change Start Time${s}`;
    break;

    case 'break_start':
      tip = "Start Breaks";
      iconClass = 'break-start';
      icon = <FiCoffee />;
      titleText = "Select the time for breaks to start";
      fieldLabel = "New Break Time";
      submitText = `Start Break${s}`;
    break;

    case 'break_end':
      tip = "End Breaks";
      iconClass = 'break-end';
      icon = (
        <><FiCoffee /><TbWalk className="walk" /></>
      );
      titleText = "Select the time for breaks to end";
      fieldLabel = "Break End Time";
      if(!_disabled && cards.filter((card) => card.clock_status === 'on_break').length === 0){
        _disabled = true;
        tip += " (Disabled - No time cards are on break)";
      }
      submitText = `End Break${s}`;
    break;

    case 'end_time':
      tip = "End Shifts";
      iconClass = 'shift-end';
      icon = (
        <div className="flex"><PunchClockIcon /><TbWalk className="walk" /></div>
      );
      titleText = "Select a time for the shifts to End";
      initialValues.end_time = moment().set("hour", 17).set("minute", 0);
      fieldLabel = "End of Shift Time";
      submitText = `End Shift${s}`;
    break;

    case 'ignore_signature':
      tip = "Mark as Complete (Ignore Signatures)";
      iconClass = 'complete';
      icon = <FaFileSignature />;
      titleText = "Mark as Complete (Ignore Signatures)";
      initialValues.ignore_signature = true;
      if(!_disabled && cards.filter((card) => card.status === 'awaiting_signature').length === 0){
        _disabled = true;
        tip += " (Disabled - No time cards are awaiting signature)";
      }
      submitText = `Complete Card${s}`;
    break;

    case 'delete':
      tip = "Delete";
      iconClass = 'delete';
      icon = <TrashIcon />;
      titleText = "Delete";
      initialValues.delete = true;
      submitText = `Delete Card${s}`;
    break;

    default:
  }
  if(!_disabled && !clockedInQty && isTimeBased && type !== 'break_end'){
    _disabled = true;
    tip += " (Disabled - No time cards are clocked in)";
  }
  if(disabled){
    tip += " (Disabled - Select time cards)"
  }

  return (
    <>
    <Tooltip title={tip}>
      <IconButton
        variant="contained"
        size="small"
        className={`${iconClass} non-mui-icon bulk-edit-icon ${_disabled ? 'disabled' : ''}`}
        onClick={() => {
          if(!_disabled){
            setOpen(true);
          }
        }}
      >
        {icon}
      </IconButton>
    </Tooltip>
    <DialogForm
      submitText={submitText}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      paperProps={{
        className: "time-clock dialog small",
      }}
      maxWidth="xs"
      title={
        <>
          <IconButton
            variant="contained"
            size="small"
            className={`${iconClass} bulk-edit-icon`}
          >
            {icon}
          </IconButton>
          <span className="ml-2">{titleText}</span>
        </>
      }
      open={open}
      onClose={() => setOpen(false)}
      directSubmit
      overrideDirty
    >
      {({ values, errors, isSubmitting, validateField, setFieldValue }) => (
        !isTimeBased ? (
          <>
          {type === "ignore_signature" &&
            <Typography variant="subtitle1">Are you sure that you want to complete the selected time cards and ignore user signatures?</Typography>
          }
          {type === "delete" &&
            <Typography variant="h5">Are you sure that you want to delete the selected time cards?</Typography>
          }
          </>
        ) : (
          <>
          <TimeFormField
            fullWidth
            disableToolbar
            variant="inline"
            id={type}
            label={fieldLabel}
            name={type}
            inputVariant="filled"
            size="small"
            KeyboardButtonProps={{
              "aria-label": fieldLabel,
            }}
            disabled={isSubmitting}
          />
          {type === "end_time" &&
            <CheckboxFormField
              name="ignore_signature"
              ignoreErrors={true}
              label="Ignore User Signatures"
            />
          }
          </>
        )
      )}
    </DialogForm>
    </>
  );
}

TimeCardBulkEditAction.propTypes = {};

export default TimeCardBulkEditAction;
