import React, { useCallback } from "react";
import { Grid, Typography } from "@mui/material";
import styled from "styled-components";
import { AiOutlineSchedule } from "react-icons/ai";
import { GoVerified } from "react-icons/go";
import { RiCalendarCheckFill } from "react-icons/ri";
import { ImClipboard } from "react-icons/im";
import moment from "moment";
import { BiCalendarExclamation } from "react-icons/bi";
import { MdUpdate } from "react-icons/md";
import DateItem from "./DateItem";
import Card, { CardContent, CardHeader } from "common/Card";
import { useSelector } from "react-redux";
import IconTextItem from "common/IconTextItem";
import { getDivisionDataSelector, getJobDataSelector } from "../jobSlice";
import { first, toArray } from "lodash";
import PaymentReceived from "features/Job/forms/PaymentReceived";
import Guard from "common/Guard";

const Style = styled.div`
  .action-item{
    cursor: pointer;
    border-radius: 3px;
    transition: background-color 0.25s;

    &:hover {
      background-color: var(--color-gray-background);
    }
  }
`;


const JobDates = () => {
  const progress = useSelector((state) => state.jobProgress.data);
  const job = useSelector(getJobDataSelector);
  const division = useSelector(getDivisionDataSelector);
  const processed = division?._processed;
  let timeFields = null;
  if(division?._processed?.milestone_requirements){
    const req = first(toArray(division?._processed?.milestone_requirements));
    if(req?.time_fields){
      timeFields = req.time_fields;
    }
  }
  const editable = Boolean(processed?.job_manage_dates);

  const checkDateDisplay = (field) => {
    if(timeFields){
      if(timeFields.includes(field) || timeFields.includes(`field_jd_progress_data::${field}`)){
        return true;
      }
      return false;
    }
    return true;
  }

  const isPastDue = useCallback(() => {
    const today = moment().format("X");
    const future = moment(division.field_completion_date).format("X");

    return today > future;
  }, [division.field_completion_date]);

  const isPastStartDue = useCallback(() => {
    const today = moment(division.field_jd_trgt_start_date).format("X");
    const future = moment(division.field_start_date).format("X");

    return today < future;
  }, [division.field_jd_trgt_start_date, division.field_start_date]);

  return (
    <Style>
    <Card>
      <CardHeader title="Dates" />
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xxs={12} md={4}>
            <IconTextItem icon={<BiCalendarExclamation />}>
              <Typography
                variant="subtitle2"
                style={{ textTransform: "uppercase" }}
                color="textSecondary"
              >
                Created
              </Typography>
              <div>
                <Typography variant="body1">
                  {moment(division.created).format("MMM Do,	YYYY")}
                </Typography>
                <Typography variant="body1">
                  {moment(division.created).format("h:mm A")}
                </Typography>
              </div>
            </IconTextItem>
          </Grid>
          <DateItem
            nid={job.nid}
            icon={<AiOutlineSchedule />}
            field="field_date"
            label="Received"
            buttonText="Received"
            value={job.field_date}
            format=""
            dateTime
            timeFields={timeFields}
            editable={editable}
          >
            <div>
              <Typography variant="body1">
                {moment(job.field_date).format("dddd")}
              </Typography>
              <Typography variant="body1">
                {`${moment(job.field_date).format("MMM Do,	YYYY")}`}
              </Typography>
            </div>
          </DateItem>
          <DateItem
            nid={division.nid}
            icon={<AiOutlineSchedule />}
            field="field_eta_datetime"
            label="ETA"
            buttonText="ETA"
            value={division.field_eta_datetime}
            format=""
            dateTime
            timeFields={timeFields}
            editable={editable}
          >
            <div>
              <Typography variant="body1">
                {moment(division.field_eta_datetime).format("MMM Do,	YYYY")}
              </Typography>
              <Typography variant="body1">
                {`${moment(division.field_eta_datetime).format("h:mm A")}`}
              </Typography>
            </div>
          </DateItem>
          <DateItem
            nid={division.nid}
            icon={<ImClipboard />}
            field="field_jd_inspection"
            label="Inspection Date"
            buttonText="Inspection Date"
            value={division.field_jd_inspection}
            format=""
            timeFields={timeFields}
            editable={editable}
          >
            <div>
              <Typography variant="body1">
                {moment(division.field_jd_inspection).format("dddd")}
              </Typography>
              <Typography variant="body1">
                {moment(division.field_jd_inspection).format("MMM Do,	YYYY")}
              </Typography>
            </div>
          </DateItem>
          <DateItem
            nid={division.nid}
            icon={<RiCalendarCheckFill />}
            field="field_start_date"
            label="Start Date"
            buttonText="Start Date"
            value={division.field_start_date}
            format="YYYY-MM-DD"
            timeFields={timeFields}
            editable={editable}
          >
            <div>
              <Typography variant="body1">
                {moment(division.field_start_date).format("dddd")}
              </Typography>
              <Typography variant="body1">
                {moment(division.field_start_date).format("MMM Do,	YYYY")}
              </Typography>
            </div>
          </DateItem>
          <DateItem
            nid={division.nid}
            icon={<GoVerified />}
            field="field_completion_date"
            label="Target Completion"
            buttonText="Target Completion"
            value={division.field_completion_date}
            format="YYYY-MM-DD"
            disableBorder
            timeFields={timeFields}
            editable={editable}
          >
            <div
              style={{
                color: isPastDue() ? "var(--color-red)" : "var(--color-black)",
              }}
            >
              <Typography variant="body1">
                {moment(division.field_completion_date).format("dddd")}
              </Typography>
              <Typography variant="body1">
                {moment(division.field_completion_date).format("MMM Do,	YYYY")}
              </Typography>
            </div>
          </DateItem>
          <DateItem
            nid={division.nid}
            icon={<BiCalendarExclamation />}
            field="field_jd_incident_date"
            label="Incident"
            buttonText="Incident"
            value={division.field_jd_incident_date}
            format="YYYY-MM-DD"
            disableBorder
            timeFields={timeFields}
            editable={editable}
          >
            <div>
              <Typography variant="body1">
                {moment(division.field_jd_incident_date).format("dddd")}
              </Typography>
              <Typography variant="body1">
                {moment(division.field_jd_incident_date).format("MMM Do,	YYYY")}
              </Typography>
            </div>
          </DateItem>
          <DateItem
            nid={division.nid}
            icon={<BiCalendarExclamation />}
            field="field_jd_contacted_date"
            label="Contacted"
            buttonText="Contacted"
            value={division.field_jd_contacted_date}
            format="YYYY-MM-DD"
            disableBorder
            timeFields={timeFields}
            editable={editable}
          >
            <div>
              <Typography variant="body1">
                {moment(division.field_jd_contacted_date).format("dddd")}
              </Typography>
              <Typography variant="body1">
                {moment(division.field_jd_contacted_date).format("MMM Do,	YYYY")}
              </Typography>
            </div>
          </DateItem>
          {checkDateDisplay("estimate_sent_timestamp") && (
            <Grid item xxs={12} md={4} /*style={{"order": (progress.estimate_sent_timestamp ? 3 : 4)}}*/>
              <IconTextItem icon={<BiCalendarExclamation />}>
                <Typography
                  variant="subtitle2"
                  style={{ textTransform: "uppercase" }}
                  color="textSecondary"
                >
                  Estimate Sent
                </Typography>
                {progress.estimate_sent_timestamp ? (
                  <div>
                    <Typography variant="body1">
                      {moment
                        .unix(progress.estimate_sent_timestamp)
                        .format("dddd")}
                    </Typography>
                    <Typography variant="body1">
                      {moment
                        .unix(progress.estimate_sent_timestamp)
                        .format("MMM Do,	YYYY")}
                    </Typography>
                  </div>
                ) : (
                  <Typography variant="body1">NA</Typography>
                )}
              </IconTextItem>
            </Grid>
          )}
          {checkDateDisplay("estimate_approved_timestamp") && (
            <Grid item xxs={12} md={4} /*style={{"order": (progress.estimate_approved_timestamp ? 3 : 4)}}*/>
              <IconTextItem icon={<BiCalendarExclamation />}>
                <Typography
                  variant="subtitle2"
                  style={{ textTransform: "uppercase" }}
                  color="textSecondary"
                >
                  Estimate Approved
                </Typography>
                {progress.estimate_approved_timestamp ? (
                  <div>
                    <Typography variant="body1">
                      {moment
                        .unix(progress.estimate_approved_timestamp)
                        .format("dddd")}
                    </Typography>
                    <Typography variant="body1">
                      {moment
                        .unix(progress.estimate_approved_timestamp)
                        .format("MMM Do,	YYYY")}
                    </Typography>
                  </div>
                ) : (
                  <Typography variant="body1">NA</Typography>
                )}
              </IconTextItem>
            </Grid>
          )}
          <DateItem
            nid={division.nid}
            icon={<BiCalendarExclamation />}
            field="field_jd_trgt_start_date"
            label="Target Start"
            buttonText="Target Start"
            value={division.field_jd_trgt_start_date}
            format="YYYY-MM-DD"
            disableBorder
            timeFields={timeFields}
            editable={editable}
          >
            <div
              style={{
                color: isPastStartDue()
                  ? "var(--color-red)"
                  : "var(--color-black)",
              }}
            >
              <Typography variant="body1">
                {moment(division.field_jd_trgt_start_date).format("dddd")}
              </Typography>
              <Typography variant="body1">
                {moment(division.field_jd_trgt_start_date).format(
                  "MMM Do,	YYYY"
                )}
              </Typography>
            </div>
          </DateItem>
          <DateItem
            nid={division.nid}
            icon={<BiCalendarExclamation />}
            field="field_jd_intro_prod_date"
            label="Intro Production"
            buttonText="Intro Production"
            value={division.field_jd_intro_prod_date}
            format=""
            disableBorder
            dateTime
            timeFields={timeFields}
            editable={editable}
          >
            <div>
              <Typography variant="body1">
                {moment(division.field_jd_intro_prod_date).format(
                  "MMM Do,	YYYY"
                )}
              </Typography>
              <Typography variant="body1">
                {`${moment(division.field_jd_intro_prod_date).format(
                  "h:mm A"
                )}`}
              </Typography>
            </div>
          </DateItem>
          <DateItem
            nid={division.nid}
            icon={<BiCalendarExclamation />}
            field="field_jd_mjrty_completion_date"
            label="Majority Completion"
            buttonText="Majority Completion"
            value={division.field_jd_mjrty_completion_date}
            format="YYYY-MM-DD"
            disableBorder
            timeFields={timeFields}
            editable={editable}
          >
            <div>
              <Typography variant="body1">
                {moment(division.field_jd_mjrty_completion_date).format("dddd")}
              </Typography>
              <Typography variant="body1">
                {moment(division.field_jd_mjrty_completion_date).format(
                  "MMM Do,	YYYY"
                )}
              </Typography>
            </div>
          </DateItem>
          <DateItem
            nid={division.nid}
            icon={<BiCalendarExclamation />}
            field="field_jd_work_complete"
            label="Completion"
            buttonText="Completion"
            value={division.field_jd_work_complete}
            format=""
            disableBorder
            timeFields={timeFields}
            editable={editable}
          >
            <div>
              <Typography variant="body1">
                {moment(division.field_jd_work_complete).format("dddd")}
              </Typography>
              <Typography variant="body1">
                {moment(division.field_jd_work_complete).format("MMM Do,	YYYY")}
              </Typography>
            </div>
          </DateItem>

          {checkDateDisplay("invoiced_timestamp") && (
            <Grid item xxs={12} md={4} /*style={{"order": (progress.invoiced_timestamp ? 3 : 4)}}*/>
              <IconTextItem icon={<BiCalendarExclamation />}>
                <Typography
                  variant="subtitle2"
                  style={{ textTransform: "uppercase" }}
                  color="textSecondary"
                >
                  Invoiced
                </Typography>
                {progress.invoiced_timestamp ? (
                  <div>
                    <Typography variant="body1">
                      {moment.unix(progress.invoiced_timestamp).format("dddd")}
                    </Typography>
                    <Typography variant="body1">
                      {moment
                        .unix(progress.invoiced_timestamp)
                        .format("MMM Do,	YYYY")}
                    </Typography>
                  </div>
                ) : (
                  <Typography variant="body1">NA</Typography>
                )}
              </IconTextItem>
            </Grid>
          )}
          {checkDateDisplay("paid_timestamp") && (
            <Grid item xxs={12} md={4} /*style={{"order": (progress.paid_timestamp ? 3 : 4)}}*/>
              <IconTextItem className={(editable ? "action-item" : '')} icon={<BiCalendarExclamation />}>
                <Typography
                  variant="subtitle2"
                  style={{ textTransform: "uppercase" }}
                  color="textSecondary"
                >
                  Paid
                </Typography>
                <PaymentReceived editable={editable}>
                  {progress.paid_timestamp ? (
                    <div>
                      <Typography variant="body1">
                        {moment.unix(progress.paid_timestamp).format("dddd")}
                      </Typography>
                      <Typography variant="body1">
                        {moment.unix(progress.paid_timestamp).format("MMM Do,	YYYY")}
                      </Typography>
                    </div>
                  ) : (
                    <Typography variant="body1">Payment not Received</Typography>
                  )}
                </PaymentReceived>
              </IconTextItem>
            </Grid>
          )}
          {checkDateDisplay("field_jd_closed") && (
            <Grid item xxs={12} md={4} /*style={{"order": (division.field_jd_closed ? 3 : 4)}}*/>
              <IconTextItem icon={<BiCalendarExclamation />}>
                <Typography
                  variant="subtitle2"
                  style={{ textTransform: "uppercase" }}
                  color="textSecondary"
                >
                  Closed
                </Typography>
                {division.field_jd_closed ? (
                  <div>
                    <Typography variant="body1">
                      {moment(division.field_jd_closed).format("dddd")}
                    </Typography>
                    <Typography variant="body1">
                      {moment(division.field_jd_closed).format("MMM Do,	YYYY")}
                    </Typography>
                  </div>
                ) : (
                  <Typography variant="body1">NA</Typography>
                )}
              </IconTextItem>
            </Grid>
          )}
          <Guard intendedRoles={["phx_sub_admin", "admin"]}>
            <DateItem
              nid={division.nid}
              icon={<BiCalendarExclamation />}
              field="field_file_followup_date"
              label="File Follow-up"
              buttonText="File Follow-up"
              value={division.field_file_followup_date}
              format=""
              disableBorder
              timeFields={["field_file_followup_date"]}
              editable={editable}
            >
            {division.field_file_followup_date && (
              <div>
                <Typography variant="body1">
                  {moment(division.field_file_followup_date).format("dddd")}
                </Typography>
                <Typography variant="body1">
                  {moment(division.field_file_followup_date).format("MMM Do,	YYYY")}
                </Typography>
              </div>
            )}
            </DateItem>
          </Guard>
          <Grid item xxs={12} md={4} /*style={{"order": 5}}*/>
            <IconTextItem icon={<MdUpdate />}>
              <Typography
                variant="subtitle2"
                style={{ textTransform: "uppercase" }}
                color="textSecondary"
              >
                Last Updated
              </Typography>
              <div>
                <Typography variant="body1">
                  {moment(division.field_last_updated).format("MMM Do,	YYYY")}
                </Typography>
                <Typography variant="body1">
                  {moment(division.field_last_updated).format("h:mm A")}
                </Typography>
              </div>
            </IconTextItem>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
    </Style>
  );
};

JobDates.propTypes = {};

export default JobDates;
