import _, { has } from "lodash";
import moment from "moment";
import { useLocation } from "react-router-dom";
import chroma from "chroma-js";
import constants from "../components/constants";
import {
  formatField,
  formatJobFields,
  formatClient,
  formatMemberFields,
} from "./format";
import { formatFieldByValue } from "utility";
import { isObject, toArray } from "lodash";

export {
  formatField,
  formatNumberField,
  formatInsurance,
  formatJobFields,
  formatCrew,
  formatMoreFloodsData,
  formatClient,
  // getAvailableClientSettings,
  // getSelectedClientSettings,
  getPhoenixClients,
  formatMemberCapability,
  formatMemberDefaultTermination,
  formatMemberEntityDetails,
  formatMemberFinancials,
  formatTerminationDoc,
  formatMemberRenewal,
  formatMemberTerritory,
  formatCRMCall,
  formatMemberCapabilityEquipment,
  formatMemberCapabilityVehicles,
  formatMemberEmployee,
  formatEmployeeEducation,
  formatAgreementDoc,
  formatMemberFields,
  formatCRMEmail,
  formatTeam,
  formatFile,
  formatEvent,
  formatPost,
  formatFieldByValue,
  formatMemberGeneralDoc,
  formatStatesOptions,
  formatChamberFields,
  formatRoomFields,
  formatInventoryItem,
  formatInventoryItemAssignment,
  formatMaintenanceRecord,
  formatMaintenanceSchedule,
  formatDirectPay,
  formatWorkAuth,
  parseRawHalJson,
  formatCIS,
  formatMFDoc,
  formatMFMonitoringChecklist,
  formatRoomSurface,
  formatCOS,
  formatTimeRangeField,
} from "./format";

export { getDailySheetsFiltersFromFragment } from "./routing";

export const getLocalStorage = (key) => {
  try {
    const item = window.localStorage.getItem(key);
    return item ? JSON.parse(item) : {};
  } catch (error) {
    console.log(error);
    return {};
  }
};

export const formatAddressField = (data) => {
  if (data && data.field_address && data.field_address[0]) {
    return {
      street: data.field_address[0].address_line1,
      city: data.field_address[0].locality,
      state: data.field_address[0].administrative_area,
      zipcode: data.field_address[0].postal_code,
      country: data.field_address[0].country_code,
    };
  }

  return {
    street: null,
    city: null,
    state: null,
    zipcode: null,
    country: null,
  };
};

export const getStatusColor = (status) => {
  const color = constants.statusColors[status]
    ? constants.statusColors[status]
    : constants.colorGray;
  const backgroundColor = chroma(color).alpha(0.25);
  const fontColor = chroma(color).darken(2);

  return [color, backgroundColor, fontColor];
};

export const formatNestedField = (object, field, nestedField) => {
  return object && object[field] && object[field][0]
    ? formatField(object[field][0], "name")
    : null;
};

export const findTestAttr = (component, attr) => {
  const element = component.find(`[data-test='${attr}']`);

  return element;
};

export const formatPhoneNumber = (string) => {
  const cleaned = ("" + string).replace(/\D/g, "");
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`;
  }
  else if(string){
    const match = string.match(constants.phoneRegExp);
    if (match) {
      return `${match[0]}`;
    }
  }
  return null;
};

export const getInitials = (first, last) => {
  return `${_.toString(first).charAt(0)}${_.toString(last).charAt(0)}`;
};

export const formatDateTime = (date, format) => {
  return moment(date).format(format);
};

export const formatFileSize = (bytes, si) => {
  var thresh = si ? 1000 : 1024;
  if (Math.abs(bytes) < thresh) {
    return bytes + " B";
  }
  var units = si
    ? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
    : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
  var u = -1;
  do {
    bytes /= thresh;
    ++u;
  } while (Math.abs(bytes) >= thresh && u < units.length - 1);

  return bytes.toFixed(1) + " " + units[u];
};

export const generateGreeting = () => {

  var currentHour = moment().format("HH");

  if (currentHour >= 3 && currentHour < 12){
      return "Good Morning";
  } else if (currentHour >= 12 && currentHour < 15){
      return "Good Afternoon";
  }   else if (currentHour >= 15 && currentHour < 20){
      return "Good Evening";
  } else if (currentHour >= 20 && currentHour < 3){
      return "Good Night";
  } else {
      return "Hello"
  }

}

export const slugify = (s) => {
  if (!s) return null;
  const slugify_strip_re = /[^\w\s-]/g;
  const slugify_hyphenate_re = /[-\s]+/g;
  s = s.replace(slugify_strip_re, "").trim().toLowerCase();
  s = s.replace(slugify_hyphenate_re, "-");
  return s;
};

export const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export const getPercentage = (obtained, total) => {
  if (!total || !obtained) return 0;
  if (parseFloat(obtained) > total) return 100;
  if (parseFloat(obtained) < 0) return 100;
  return Math.round((parseFloat(obtained) * 100) / parseFloat(total));
};

export const getProgressColor = (value, colorStart, colorEnd) => {
  const color = chroma.scale([colorStart, colorEnd]).colors(101);
  return color[value];
};

export const getNumActiveFilters = (filters) => {
  for (let type in filters) {
    if (_.isObject(filters[type]) && !_.isArray(filters[type])) {
      return true;
    } else if (_.isArray(filters[type])) {
      if (filters[type].length) return true;
    } else if (filters[type] && filters[type].length) return true;
  }

  return false;
};

export const formatDivisionJobFields = (job) => {
  const fieldDateReceived = formatField(job, "field_date");
  const fieldCustomer = formatField(job, "field_customer");
  const fieldCustomerLocation = formatField(job, "field_customer_location");
  const fieldPhoenixClient = formatField(job, "field_phoenix_client");

  return {
    links: job && job._links ? job._links : null,
    nid: formatField(job, "nid"),
    title: formatField(job, "title"),
    category: formatField(job, "field_loss_category"),
    jobSource: formatField(job, "field_job_source"),
    dateReceived: fieldDateReceived
      ? moment(fieldDateReceived).format("MMM Do, YYYY")
      : null,
    fieldDateReceived: formatField(job, "field_date"),
    divisions: getValuesFromFieldArray(job, "field_job_divisions"),
    numDivisions: getNumValuesInFieldArray(job, "field_job_divisions"),
    customer: formatCustomerFields(fieldCustomer),
    location: formatCustomerLocationFields(fieldCustomerLocation),
    reported: formatField(job, "field_reported_by"),
    rep: formatUser(formatField(job, "field_phx_client_rep")),
    notes: formatField(job, "field_note"),
    client: formatClient(fieldPhoenixClient),
  };
};

export const getContacts = (object) => {
  const primary = formatField(object, "field_primary_poc");
  const phx = formatField(object, "field_phx_certified_poc");
  const primaryPoc = [];
  const secondaryPocs = [];
  const afterHoursPocs = [];
  const phxClientRep = [];
  const phxPoc = [];

  if (primary) {
    primaryPoc.push({
      uid: formatField(primary, "uid"),
      name: formatField(primary, "name"),
      first: formatField(primary, "field_first_name"),
      last: formatField(primary, "field_last_name"),
      title: formatField(primary, "field_title_position"),
      mail: formatField(primary, "mail"),
      phone: formatField(primary, "field_phone"),
      cell: formatField(primary, "field_cell"),
      alias:
        primary && primary.path && primary.path[0] && primary.path[0].alias
          ? primary.path[0].alias
          : null,
    });
  }

  if (phx) {
    phxPoc.push({
      uid: formatField(phx, "uid"),
      name: formatField(phx, "name"),
      first: formatField(phx, "field_first_name"),
      last: formatField(phx, "field_last_name"),
      title: formatField(phx, "field_title_position"),
      mail: formatField(primary, "mail"),
      phone: formatField(primary, "field_phone"),
      cell: formatField(primary, "field_cell"),
      alias:
        phx && phx.path && phx.path[0] && phx.path[0].alias
          ? phx.path[0].alias
          : null,
    });
  }

  if (object && object.field_secondary_pocs) {
    object.field_secondary_pocs.forEach((contact) => {
      secondaryPocs.push({
        uid: formatField(contact, "uid"),
        name: formatField(contact, "name"),
        first: formatField(contact, "field_first_name"),
        last: formatField(contact, "field_last_name"),
        title: formatField(contact, "field_title_position"),
        mail: formatField(primary, "mail"),
        phone: formatField(primary, "field_phone"),
        cell: formatField(primary, "field_cell"),
        alias:
          contact && contact.path && contact.path[0] && contact.path[0].alias
            ? contact.path[0].alias
            : null,
      });
    });
  }

  if (object && object.field_after_hours_pocs) {
    object.field_after_hours_pocs.forEach((contact) => {
      afterHoursPocs.push({
        uid: formatField(contact, "uid"),
        name: formatField(contact, "name"),
        first: formatField(contact, "field_first_name"),
        last: formatField(contact, "field_last_name"),
        title: formatField(contact, "field_title_position"),
        mail: formatField(primary, "mail"),
        phone: formatField(primary, "field_phone"),
        cell: formatField(primary, "field_cell"),
        alias:
          contact && contact.path && contact.path[0] && contact.path[0].alias
            ? contact.path[0].alias
            : null,
      });
    });
  }

  if (object && object.field_phx_client_rep) {
    object.field_phx_client_rep.forEach((contact) => {
      phxClientRep.push({
        uid: formatField(contact, "uid"),
        name: formatField(contact, "name"),
        first: formatField(contact, "field_first_name"),
        last: formatField(contact, "field_last_name"),
        title: formatField(contact, "field_title_position"),
        mail: formatField(primary, "mail"),
        phone: formatField(primary, "field_phone"),
        cell: formatField(primary, "field_cell"),
        alias:
          contact && contact.path && contact.path[0] && contact.path[0].alias
            ? contact.path[0].alias
            : null,
      });
    });
  }

  return { primaryPoc, secondaryPocs, afterHoursPocs, phxClientRep, phxPoc };
};

export const getPathFromLinksSelf = (obj) => {
  if (obj && obj._links && obj._links.self && obj._links.self.href) {
    try {
      return new URL(obj._links.self.href).pathname;
    } catch (err) {
      return null;
    }
  } else {
    return null;
  }
};

// export const formatMemberFields = (member) => {
//   const logo = formatField(member, "field_logo");
//   const logoUrl = logo ? logo._links.self.href : null;
//   const path =
//     member && member.path && member.path[0] && member.path[0].alias
//       ? member.path[0].alias
//       : null;
//   const hasNotesAccess =
//     member && member._meta && member._meta.notes_access_permitted
//       ? member._meta.notes_access_permitted
//       : false;
//   const hasComplianceAccess =
//     member && member._meta && member._meta.compliance_activity_access_permitted
//       ? member._meta.compliance_activity_access_permitted
//       : false;

//   return {
//     links: member && member._links ? member._links : null,
//     self: getPathFromLinksSelf(member),
//     nid: formatField(member, "nid"),
//     name: formatField(member, "title"),
//     isParent: formatField(member, "field_is_parent_member"),
//     g2: formatField(member, "field_g2"),
//     d2d: formatField(member, "field_d2d"),
//     notes: formatField(member, "field_note"),
//     number: formatField(member, "field_next_job_number"),
//     region: getTaxonomyFields(member, "field_region"),
//     hasNotesAccess,
//     hasComplianceAccess,
//     logo: logoUrl,
//     path,
//     hasDailySheets: formatField(member, "field_daily_sheets_member"),
//     clients:
//       member && member.field_phoenix_clients
//         ? getPhoenixClients(member.field_phoenix_clients)
//         : [],
//     officeId: formatField(member, "field_office_id"),
//     affiliation: getTaxonomyFields(member, "field_affiliation"),
//     location: getTaxonomyFields(member, "field_region"),
//     officeAddress: formatField(member, "field_street_address"),
//     billingAddress: formatField(member, "field_billing_address"),
//     phone: formatField(member, "field_phone"),
//     afterHoursPhone: formatField(member, "field_phone_after_hours"),
//     fax: formatField(member, "field_fax"),
//     status: formatField(member, "field_status"),
//     hours: formatField(member, "field_hours"),
//     contacts: getContacts(member),
//     hasAimeAccess: formatField(member, "field_allow_askaime_access"),
//     nationalContracts:
//       member && member.field_national_contracts
//         ? member.field_national_contracts
//         : [],
//     aimeFiles:
//       member && member.field_askaime_files ? member.field_askaime_files : [],
//     geo:
//       member &&
//       member.field_street_address_geo &&
//       member.field_street_address_geo[0]
//         ? {
//             lat: member.field_street_address_geo[0].lat,
//             lng: member.field_street_address_geo[0].lon,
//           }
//         : {
//             lat: null,
//             lng: null,
//           },
//     website:
//       member && member.field_website ? member.field_website[0].uri : null,
//     zoom:
//       member && member.field_video_call_url
//         ? member.field_video_call_url[0].uri
//         : null,
//     field_mf_options: formatMFOptions(member),
//   };
// };

export const formatCustomerFields = (customer) => {
  const logo = formatField(customer, "field_logo");
  const logoUrl = logo ? logo._links.self.href : null;
  const path =
    customer && customer.path && customer.path[0] && customer.path[0].alias
      ? customer.path[0].alias
      : null;
  const hasNotesAccess =
    customer && customer._meta && customer._meta.notes_access_permitted
      ? customer._meta.notes_access_permitted
      : false;
  return {
    links: customer && customer._links ? customer._links : null,
    type: "node/customer",
    client: formatField(customer, "field_phoenix_client"),
    isFMPilotClient: formatField(customer, "field_is_fm_pilot_client"),
    self: getPathFromLinksSelf(customer),
    path,
    nid: formatField(customer, "nid"),
    name: formatField(customer, "title"),
    isParent: formatField(customer, "field_is_parent_customer"),
    isHeadquarters: formatField(customer, "field_is_headquarters"),
    hasNotesAccess,
    hasDailySheets: formatField(customer, "field_daily_sheets_customer"),
    contacts: getContacts(customer),
    phone: formatField(customer, "field_phone"),
    afterHoursPhone: formatField(customer, "field_phone_after_hours"),
    fax: formatField(customer, "field_fax"),
    officeAddress: formatField(customer, "field_street_address"),
    billingAddress: formatField(customer, "field_billing_address"),
    status: formatField(customer, "field_customer_status"),
    logo: logoUrl,
    activeJobs: formatField(customer, "field_customer_jd_active"),
    activeLocations: formatField(customer, "field_customer_locations_active"),
    specialInstructions: formatField(customer, "field_note"),
    hours: formatField(customer, "field_hours"),
    term: formatField(customer, "field_customer_invoice_terms"),
    discount: formatField(customer, "field_customer_discount"),
    discountAmount: formatField(customer, "field_customer_discount_amount"),
    discountPercent: formatField(customer, "field_customer_discount_percent"),
    referralRecon: formatField(customer, "field_referral_recon"),
    referralMitigation: formatField(customer, "field_referral_mitigation"),
    reconServiceFee: formatField(customer, "field_recon_service_fee"),
    reconServiceFeeWork: formatField(customer, "field_recon_service_fee_work"),
    emergencyServiceFee: formatField(customer, "field_emergency_service_fee"),
    invoiceTemplate: formatField(customer, "field_jda_invoice_template"),
    emergencyServiceFeeWork: formatField(
      customer,
      "field_emergency_service_fee_work"
    ),
    referredMember: formatMemberFields(
      customer &&
        customer.field_referred_member &&
        customer.field_referred_member[0]
        ? customer.field_referred_member[0]
        : null
    ),
    fileFees:
      customer && customer.field_file_fees ? customer.field_file_fees : [],
    geo:
      customer &&
      customer.field_street_address_geo &&
      customer.field_street_address_geo[0]
        ? {
            lat: customer.field_street_address_geo[0].lat,
            lng: customer.field_street_address_geo[0].lon,
          }
        : {
            lat: null,
            lng: null,
          },
    documents:
      customer && customer.field_cust_files ? customer.field_cust_files : [],
    website:
      customer && customer.field_website && customer.field_website[0]
        ? customer.field_website[0].uri
        : null,
    zoom:
      customer &&
      customer.field_video_call_url &&
      customer.field_video_call_url[0]
        ? customer.field_video_call_url[0].uri
        : null,
    referredByText: formatField(customer, "field_referred_by_text"),
    referredByName: formatField(customer, "field_referred_by_cmpny"),
    referredByPhone: formatField(customer, "field_referred_by_phone"),
  };
};

export const formatCustomerLocationFields = (location) => {
  const path =
    location && location.path && location.path[0] && location.path[0].alias
      ? location.path[0].alias
      : null;

  return {
    links: location && location._links ? location._links : null,
    nid: formatField(location, "nid"),
    name: formatField(location, "title"),
    path,
    geo:
      location &&
      location.field_street_address_geo &&
      location.field_street_address_geo[0]
        ? {
            lat: location.field_street_address_geo[0].lat,
            lng: location.field_street_address_geo[0].lon,
          }
        : {
            lat: null,
            lng: null,
          },
    notes: formatField(location, "field_note"),
    phone: formatField(location, "field_phone"),
    afterHours: formatField(location, "field_phone_after_hours"),
    contacts: getContacts(location),
    status: formatField(location, "field_cl_status"),
    hours: formatField(location, "field_hours"),
    sqft: formatField(location, "field_square_feet"),
    changed: formatField(location, "changed"),
    officeAddress: formatField(location, "field_street_address"),
    address1: formatField(location, "address_line1"),
    address2: formatField(location, "address_line2"),
    state: formatField(location, "administrative_area"),
    county: getTaxonomyFields(location, "field_county"),
    city: formatField(location, "locality"),
    zip: formatField(location, "postal_code"),
    country: formatField(location, "country_code"),
    isNew: formatField(location, "is_new"),
    year: formatField(location, "field_cl_year_built"),
    email: formatField(location, "field_cl_email"),
    primaryPreferred: formatMemberFields(
      location && location.field_preferred_member
        ? location.field_preferred_member[0]
        : null
    ),
    secondPreferred: formatMemberFields(
      location && location.field_preferred_member_2
        ? location.field_preferred_member_2[0]
        : null
    ),
    thirdPreferred: formatMemberFields(
      location && location.field_preferred_member_3
        ? location.field_preferred_member_3[0]
        : null
    ),
    fourthPreferred: formatMemberFields(
      location && location.field_preferred_member_4
        ? location.field_preferred_member_4[0]
        : null
    ),
  };
};

export const formatWorkarea = (area) => {
  return {
    links: area && area._links ? area._links : null,
    nid: formatField(area, "nid"),
    title: formatField(area, "title"),
    location: formatField(area, "field_da_location"),
    type: getTaxonomyFields(area, "field_da_type"),
    power: formatField(area, "field_da_power"),
    demo: formatField(area, "field_da_demo"),
    sqft: formatField(area, "field_da_area_size"),
    ft: formatField(area, "field_da_linear_affected"),
    safety: formatField(area, "field_da_life_safety"),
    safetyDesc: formatField(area, "field_da_life_safety_descript"),
    notes: formatField(area, "field_da_notes"),
    water: formatField(area, "field_da_water_present"),
    waterHeight: formatField(area, "field_da_height_water"),
    customerApproval: formatField(area, "field_cust_approval_needed"),
    general: formatField(area, "field_da_is_general"),
    tax: formatField(area, "field_consumables_tax"),
  };
};

export const getTempUserFields = (object, field) => {
  if (!object || !object[field]) return null;
  if (!_.has(object, field)) return null;

  const fieldValues = object[field].map((field) => {
    return {
      nid: formatField(field, "nid"),
      name: formatField(field, "title"),
      created: formatField(field, "created"),
      changed: formatField(field, "changed"),
    };
  });

  if (!fieldValues.length) return null;
  return fieldValues;
};

export const formatService = (ds) => {
  return {
    links: ds && ds._links ? ds._links : null,
    nid: formatField(ds, "nid"),
    type: formatField(ds, "type"),
    status: formatField(ds, "field_ds_service_status"),
    laborTypeTaxonomy: getTaxonomyFields(ds, "field_ds_labor_type"),
    date: formatField(ds, "field_ds_date"),
    start: formatField(ds, "field_ds_start_time"),
    end: formatField(ds, "field_ds_end_time"),
    user: getUserFields(ds, "field_ds_user"),
    tempUser: getTempUserFields(ds, "field_ds_user_temp"),
    activity: formatField(ds, "field_ds_service_activity_text"),
    activityTaxonomy: getTaxonomyFields(ds, "field_ds_service_activity"),
    labor: formatField(ds, "field_ds_labor_type_text"),
    coordinates: formatField(ds, "field_ds_coordinates"),
    billable: formatField(ds, "field_ds_billable"),
    excludeInvoice: formatField(ds, "field_ds_exclude_invoice"),
    onsite: formatField(ds, "field_ds_onsite"),
    afterHoursBillHrs: formatField(ds, "field_ds_after_hours_bill_hrs"),
    afterHoursBillOvrHrs: formatField(ds, "field_ds_after_hours_bll_ovr_hrs"),
    afterHoursHrs: formatField(ds, "field_ds_after_hours_hrs"),
    afterHoursOvrRate: formatField(ds, "field_ds_after_hours_ovr_rate"),
    afterHoursRate: formatField(ds, "field_ds_after_hours_rate"),
    afterHoursTotal: formatField(ds, "field_ds_after_hours_total"),
    billableHrs: formatField(ds, "field_ds_billable_hrs"),
    hasPerdiem: formatField(ds, "field_ds_has_perdiem"),
    perdiem: formatField(ds, "field_ds_perdiem_total"),
    workarea: formatWorkarea(
      ds.field_ds_work_area_allocation && ds.field_ds_work_area_allocation[0]
        ? ds.field_ds_work_area_allocation[0]
        : null
    ),
    breaks: {
      start:
        ds && ds.field_ds_breaks && ds.field_ds_breaks[0]
          ? formatField(ds.field_ds_breaks[0], "field_ds_breaks_start_time")
          : null,
      end:
        ds && ds.field_ds_breaks && ds.field_ds_breaks[0]
          ? formatField(ds.field_ds_breaks[0], "field_ds_breaks_end_time")
          : null,
    },
    breakHrs: formatField(ds, "field_ds_break_hrs"),
    entryType: formatField(ds, "field_ds_entry_type"),
    overrideBillHrs: formatField(ds, "field_ds_override_billable_hrs"),
    overrideRate: formatField(ds, "field_ds_override_rate"),
    overtimeBillHrs: formatField(ds, "field_ds_overtime_billable_hrs"),
    overtimeBillOvrHrs: formatField(ds, "field_ds_overtime_bill_ovr_hrs"),
    overtimeHrs: formatField(ds, "field_ds_overtime_hrs"),
    overtimeOvrRate: formatField(ds, "field_ds_overtime_ovr_rate"),
    overtimeRate: formatField(ds, "field_ds_overtime_rate"),
    overtimeTotal: formatField(ds, "field_ds_overtime_total"),
    rate: formatField(ds, "field_ds_rate"),
    rateType: formatField(ds, "field_ds_rate_type"),
    serviceHrs: formatField(ds, "field_ds_service_hrs"),
    serviceTotal: formatField(ds, "field_ds_service_total"),
    total: formatField(ds, "field_ds_total"),
    totalHrs: formatField(ds, "field_ds_total_hours"),
    travelHrs: formatField(ds, "field_ds_travel_hrs"),
    travelBillHrs: formatField(ds, "field_ds_travel_billable_hrs"),
    travelBillOvrHrs: formatField(ds, "field_ds_travel_billable_ovr_hrs"),
    travelOvrRate: formatField(ds, "field_ds_travel_ovr_rate"),
    travelRate: formatField(ds, "field_ds_travel_rate"),
    travelTotal: formatField(ds, "field_ds_travel_total"),
    description: formatField(ds, "field_ds_private_description"),
    showDescription: formatField(ds, "field_ds_show_descript_invoice"),
  };
};

/*export const formatExpense = (ds) => {
  return {
    links: ds && ds._links ? ds._links : null,
    nid: formatField(ds, "nid"),
    date: formatField(ds, "field_ds_date"),
    user: getUserFields(ds, "field_ds_user"),
    description: formatField(ds, "field_ds_description"),
    excludeInvoice: formatField(ds, "field_ds_exclude_invoice"),
    cost: formatField(ds, "field_ds_expense_cost"),
    total: formatField(ds, "field_ds_total"),
    typeText: formatField(ds, "field_ds_expense_type_text"),
    type: getTaxonomyFields(ds, "field_ds_expense_type"),
    markup: formatField(ds, "field_ds_percent_markup"),
    reimburse: formatField(ds, "field_ds_reimburse"),
    showDescOnInvoice: formatField(ds, "field_ds_description_show"),
    showPrivateDescOnInvoice: formatField(ds, "field_ds_show_descript_invoice"),
    receipt: getFileFields(ds, "field_ds_receipt"),
  };
};*/

// export const formatEquipment = (ds) => {
//   return {
//     links: ds && ds._links ? ds._links : null,
//     nid: formatField(ds, "nid"),
//     date: formatField(ds, "field_ds_date"),
//     user: getUserFields(ds, "field_ds_user"),
//     total: formatField(ds, "field_ds_total"),
//     excludeInvoice: formatField(ds, "field_ds_exclude_invoice"),
//     workarea: formatWorkarea(
//       ds.field_ds_work_area_allocation && ds.field_ds_work_area_allocation[0]
//         ? ds.field_ds_work_area_allocation[0]
//         : null
//     ),
//     resources:
//       ds && ds._processed && ds._processed.resources
//         ? ds._processed.resources
//         : null,
//   };
// };

// export const formatInvoice = (ds) => {
//   return {
//     links: ds && ds._links ? ds._links : null,
//     nid: formatField(ds, "nid"),
//     date: formatField(ds, "field_invoice_date"),
//     user: getUserFields(ds, "uid"),
//     submitTo:
//       ds._processed && ds._processed.customer
//         ? ds._processed.customer
//         : {
//             address: null,
//             id: null,
//             location_title: null,
//             name: null,
//           },
//     reference: formatField(ds, "field_invoice_in_reference_to"),
//     title: formatField(ds, "title"),
//     start: formatField(ds, "field_invoice_date_start"),
//     end: formatField(ds, "field_invoice_date_through"),
//     terms: formatField(ds, "field_invoice_terms"),
//     notes: formatField(ds, "field_invoice_notes"),
//     // items:
//     //   ds && ds.field_invoice_items_ent_ref
//     //     ? ds.field_invoice_items_ent_ref
//     //     : null,
//     discountAmount: formatField(ds, "field_invoice_discount_amount"),
//     discountPercent: formatField(ds, "field_invoice_discount_percent"),
//     taxRate: formatField(ds, "field_invoice_tax_rate"),
//     total: formatField(ds, "field_invoice_total"),
//     totalDiscount: formatField(ds, "field_invoice_total_discount"),
//     totalTax: formatField(ds, "field_invoice_total_tax"),
//     status: formatField(ds, "field_invoice_status"),
//     paidStatus: formatField(ds, "field_invoice_paid_status"),
//     includeReceipts: formatField(ds, "field_invoice_include_receipts"),
//   };
// };

const formatComplianceDivisions = (d) => {
  if (d && d.field_compliance_divisions) {
    const divisions = [];
    d.field_compliance_divisions.forEach((divisionType) => {
      divisions.push({
        name: formatField(divisionType, "name"),
        tid: formatField(divisionType, "tid"),
      });
    });

    return divisions;
  } else return [];
};

export const formatComplianceActivity = (d) => {
  return {
    links: d && d._links ? d._links : null,
    startDate: formatField(d, "field_compliance_start_date"),
    reason: getTaxonomyFields(d, "field_compliance_change_reason"),
    method: formatField(d, "field_compliance_lift_method"),
    liftDate: formatField(d, "field_compliance_lift_date"),
    status: formatField(d, "field_status"),
    divisions: formatComplianceDivisions(d),
    members: formatMemberFields(formatField(d, "field_compliance_members")),
    description: formatField(d, "field_compliance_description"),
  };
};

export const formatInvoiceItem = (ds) => {
  const types = {
    ds_expense: "Expense",
    ds_equipment: "Equipment",
    ds_service: "Service",
  };
  const type =
    ds && ds.type && ds.type[0] && ds.type[0].target_id
      ? types[ds.type[0].target_id]
      : null;

  const serviceSummary = formatField(ds, "field_ds_labor_type_text");
  const expenseSummary = formatField(ds, "field_ds_expense_type_text");

  return {
    links: ds && ds._links ? ds._links : null,
    nid: formatField(ds, "nid"),
    date: formatField(ds, "field_ds_date"),
    user: getUserFields(ds, "field_ds_user"),
    category: type,
    summary: serviceSummary
      ? serviceSummary
      : expenseSummary
      ? expenseSummary
      : "Equipment",
    rate: null,
    total: formatField(ds, "field_ds_total"),
  };
};

export const formatUser = (user) => {
  const pic = formatField(user, "field_profile_pic");
  const picUrl = pic ? pic._links.self.href : null;

  const userSettings = formatField(user, "field_user_settings");
  if(userSettings.service_types){
    userSettings.service_types = JSON.parse(userSettings.service_types);
  }
  else{
    userSettings.service_types = {};
  }

  return {
    uid: formatField(user, "uid"),
    status: formatField(user, "status"),
    created: moment(user?.created[0].value).format("MM-DD-YYYY hh:mm a"),
    changed: moment(user?.changed[0].value).format("MM-DD-YYYY hh:mm a"),
    name: formatField(user, "name"),
    first: formatField(user, "field_first_name"),
    last: formatField(user, "field_last_name"),
    title: formatField(user, "field_title_position"),
    roles: getRoles(user, "roles"),
    mfRoles: getMoreFloodsRoles(user, "field_mf_role"),
    pic: picUrl,
    mail: formatField(user, "mail"),
    field_phone: formatField(user, "field_phone"),
    field_cell: formatField(user, "field_cell"),
    field_company: formatField(user, "field_company"),
    field_web_socket_token: formatField(user, "field_web_socket_token"),
    field_user_settings: userSettings,
    field_sms_settings: user.field_sms_settings,
    timezone: formatField(user, "timezone"),
    address: formatAddressField(user),
    field_members: toArray(user?.field_members).map((member) => {
      const logo = formatField(member, "field_logo");
      const logoUrl = logo ? logo._links.self.href : null;
      return ({
        nid: formatField(member, "nid"),
        title: formatField(member, "title"),
        logo: logoUrl,
        field_office_id: formatField(member, "field_office_id"),
        field_allow_askaime_access: formatField(
          member,
          "field_allow_askaime_access"
        ),
        field_web_socket_token: formatField(member, "field_web_socket_token"),
        field_highlight_color: formatField(member, "field_highlight_color"),
      });
    }),
    field_customers: toArray(user?.field_customers).map((customer) => ({
      nid: formatField(customer, "nid"),
      title: formatField(customer, "title"),
    })),
    field_clients: toArray(user?.field_phoenix_clients).map((client) => {
      const logo = formatField(client, "field_pc_logo");
      const logoUrl = logo ? logo._links.self.href : null;
      const icon = formatField(client, "field_icon");
      const iconUrl = icon ? icon._links.self.href : null;
      return ({
        nid: formatField(client, "nid"),
        title: formatField(client, "title"),
        logo: logoUrl,
        icon: iconUrl,
        field_pc_proper_name: formatField(client, "field_pc_proper_name"),
        field_web_socket_token: formatField(client, "field_web_socket_token"),
        field_highlight_color: formatField(client, "field_highlight_color"),
        _processed: client?._processed,
      });
    }),
    _processed: {
      ...user?._processed,
      last_accessed: Number(user?._processed?.last_accessed),
      job_create_clients: toArray(user?._processed?.job_create_clients).map((client) => ({
        nid: formatField(client, "nid"),
        title: formatField(client, "title"),
        field_pc_proper_name: formatField(client, "field_pc_proper_name"),
        _processed: client?._processed,
      })),
    },
  };
};

export const formatDailyValidation = (dv) => {
  return {
    required: formatField(dv, "field_ds_validation_no_cust_appr"),
    first: formatField(dv, "field_ds_validation_cust_fname"),
    last: formatField(dv, "field_ds_validation_cust_lname"),
    email: formatField(dv, "field_ds_validation_cust_email"),
    title: formatField(dv, "field_ds_validation_cust_title"),
    company: formatField(dv, "field_ds_validation_cust_cmpy"),
  };
};

export const formatTask = (task) => {
  const end =
    task &&
    task.field_task_date &&
    task.field_task_date[0] &&
    task.field_task_date[0].end_value
      ? task.field_task_date[0].end_value
      : null;
  const rrule =
    task &&
    task.field_task_date &&
    task.field_task_date[0] &&
    task.field_task_date[0].rrule
      ? task.field_task_date[0].rrule
      : null;
  const infinite =
    task &&
    task.field_task_date &&
    task.field_task_date[0] &&
    task.field_task_date[0].infinite
      ? task.field_task_date[0].infinite
      : false;
  const job =
    task && task.field_task_job_number && task.field_task_job_number[0]
      ? task.field_task_job_number[0]
      : null;

  return {
    nid: formatField(task, "nid"),
    title: formatField(task, "title"),
    start: formatField(task, "field_task_date"),
    infinite,
    end,
    rrule,
    allDay: formatField(task, "field_task_all_day"),
    complete: formatField(task, "field_task_completed"),
    body: formatField(task, "body"),
    priority: getTaxonomyFields(task, "field_task_priority"),
    users: getUserFields(task, "field_task_users"),
    job: formatJobFields(job),
    reminder: formatField(task, "field_task_reminder"),
    taskType: getTaxonomyFields(task, "field_task_type"),
    monitoringChecklists:
      task && task.field_mf_monitoring_checklist
        ? task.field_mf_monitoring_checklist
        : [],
  };
};

export const getRoles = (obj, field) => {
  const roles = [];
  if (!obj || !obj[field]) return roles;
  obj[field].forEach((role) => {
    roles.push(role.target_id);
  });

  return roles;
};

export const getMoreFloodsRoles = (obj, field) => {
  const roles = [];
  if (!obj || !obj[field]) return roles;
  obj[field].forEach((role) => {
    roles.push(role.value);
  });

  return roles;
};

export const getFileFields = (object, field) => {
  if (!object || !object[field]) return null;
  if (!_.has(object, field)) return null;

  const fieldValues = object[field].map((field) => {
    return {
      fid: formatField(field, "fid"),
      uuid: formatField(field, "uuid"),
      filename: formatField(field, "filename"),
      filemime: formatField(field, "filemime"),
      filesize: formatField(field, "filesize"),
      uri: formatField(field, "uri"),
      url: formatFieldByValue(field, "uri", "url"),
      created: formatField(field, "created"),
      changed: formatField(field, "changed"),
      previews: field && field._previews ? field._previews : null,
      link:
        field && field._links && field._links.self && field._links.self.href
          ? field._links.self.href
          : null,
      downloadToken: field?._download_token,
      file_dl_token: field?._download_token,
    };
  });

  if (!fieldValues.length) return null;
  return fieldValues;
};

export const getTaxonomyFields = (object, field) => {
  if (!object || !object[field] || !object[field][0])
    return { tid: null, name: null };
  if (!_.has(object, field)) return { tid: null, name: null };

  const obj = object[field][0];
  return {
    tid: formatField(obj, "tid"),
    name: formatField(obj, "name"),
    machine_name: formatField(obj, "field_machine_name"),
  };
};

export const getUserFields = (object, field) => {
  if (!object || !object[field]) return null;
  if (!_.has(object, field)) return null;

  const fieldValues = object[field].map((field) => {
    return {
      uid: formatField(field, "uid")
        ? formatField(field, "uid")
        : formatField(field, "nid")
        ? formatField(field, "nid")
        : null,
      uuid: formatField(field, "uuid"),
      name: formatField(field, "name")
        ? formatField(field, "name")
        : formatField(field, "title")
        ? formatField(field, "title")
        : null,
      status: formatField(field, "status"),
      created: formatField(field, "created"),
      changed: formatField(field, "changed"),
      first: formatField(field, "field_first_name"),
      last: formatField(field, "field_last_name"),
      mail: formatField(field, "mail"),
      phone: formatField(field, "field_phone"),
      fieldTitlePosition: formatField(field, "field_title_position"),
      fieldProfilePic: formatField(field, "field_profile_pic"),
      path:
        field && field.path && field.path[0] && field.path[0].alias
          ? field.path[0].alias
          : null,
      _processed: field?._processed ? {...field?._processed} : {},
    };
  });

  if (!fieldValues.length) return null;
  return fieldValues;
};

export const getValuesFromFieldArray = (object, field) => {
  if (!object || !object[field]) return [];
  if (!has(object, field)) return [];

  const fieldValues = object[field].map((field) => {
    return {
      tid: formatField(field, "tid"),
      name: formatField(field, "name"),
      uuid: formatField(field, "uuid"),
      title: formatField(field, "title"),
      fieldTitlePosition: formatField(field, "field_title_position"),
      fieldProfilePic: formatField(field, "field_profile_pic"),
    };
  });

  if (!fieldValues.length) return null;
  return fieldValues;
};

export const getNumValuesInFieldArray = (object, field) => {
  const fieldArray = getValuesFromFieldArray(object, field);

  if (!fieldArray) return 0;
  return fieldArray.length;
};

export const formatHours = (h, d) => {
  if (!h || !d) return null;
  const open = h[`${d}_open`];
  const close = h[`${d}_close`];
  const closed = h[`${d}_closed`];
  const allDay = h[`${d}_24hours`];

  if (Number(closed) === 1) return "Closed";
  if (Number(allDay) === 1) return "Open 24 Hours";
  if (!open || !open.length || !close || !close.length) return null;

  return `${open} - ${close}`;
};

export const getHours = (h) => {
  if (!h) return [];

  const _hours = [
    { day: "monday", hours: formatHours(h, "monday") },
    { day: "tuesday", hours: formatHours(h, "tuesday") },
    { day: "wednesday", hours: formatHours(h, "wednesday") },
    { day: "thursday", hours: formatHours(h, "thursday") },
    { day: "friday", hours: formatHours(h, "friday") },
    { day: "saturday", hours: formatHours(h, "saturday") },
    { day: "sunday", hours: formatHours(h, "sunday") },
  ];

  const hours = [];
  _hours.forEach((hour) => {
    if (hour.hours) hours.push(hour);
  });
  return hours;
};

export const getTodaysDay = () => {
  const days = [
    "sunday",
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
  ];
  const today = moment().day();
  return days[today];
};

export const getTodaysHours = (hours, today) => {
  if (!hours || !today) return;
  let now = null;
  hours.forEach((hour) => {
    if (hour.day === today) now = hour.hours;
  });

  return now;
};

export const getCounties = (served, owned) => {
  if (!served && !owned) return [];
  const counties = [];
  served.forEach((county) => {
    const name = formatField(county, "name");
    const state =
      county.parent && county.parent[0] && county.parent[0]
        ? formatField(county.parent[0], "name")
        : null;
    let countyOwned = false;
    if (owned) {
      owned.forEach((own) => {
        const ownedName = formatField(own, "name");
        if (ownedName === name) countyOwned = true;
      });
    }
    counties.push({ name: name, owned: countyOwned, state });
  });

  return counties.sort((a, b) => (a.owned === b.owned ? 0 : a.owned ? -1 : 1));
};

export const stringToColor = (str) => {
  if (!str) return constants.colorGrayLight;
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  let color = "#";
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff;
    color += ("00" + value.toString(16)).substr(-2);
  }

  if (chroma(color).get("hsl.s") > 0.85) {
    return chroma(color).set("hsl.s", 0.85).get("hex");
  }

  return color;
};

export const formatDispatchTime = (str) => {
  if (!str) return "NA";
  const time = str.toLowerCase();
  const times = time.split(" ");
  const formattedTimes = times.map((t) => {
    if (
      t === "weeks" ||
      t === "week" ||
      t === "days" ||
      t === "day" ||
      t === "hours" ||
      t === "hour" ||
      t === "minutes" ||
      t === "minute" ||
      t === "second" ||
      t === "seconds" ||
      t === "months" ||
      t === "month"
    ) {
      return t.charAt(0);
    }

    return t;
  });

  return formattedTimes.join(" ");
};

export const convertBlobToBase64 = (blob) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = reject;
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.readAsDataURL(blob);
  });

export const getFileDimensions = (base64) =>
  new Promise((resolve, reject) => {
    var image = new Image();

    image.onerror = reject;
    image.onload = function() {
      resolve(this);
    };
    image.src = base64;
  });

export const convertFileToArrayBuffer = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = reject;
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.readAsArrayBuffer(file);
  });

export const removeCurrencyFormatting = (str) => {
  if (!str) return 0;
  if (str === '' || typeof str === "number") return str;
  return Number(str.replace(/\$|,/g, ""));
};

export const getUtcOffset = () => {
  const offset = moment().utcOffset();
  if (offset > 0) return -Math.abs(offset);

  return Math.abs(offset);
};

export const pad = (n, width, z) => {
  z = z || "0";
  n = n + "";
  return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
};

export const formatDecimalToTime = (d) => {
  if (!d) return null;
  const remainder = d % 1;
  const hours = Math.floor(d);
  const minutes = Math.round(remainder * 60);

  return `${hours}:${pad(minutes, 2)}`;
};

// export const formatMoney = (
//   amount,
//   decimalCount = 2,
//   decimal = ".",
//   thousands = ","
// ) => {
//   if (!amount) return "$0.00";
//   let temp = amount;
//   if (typeof temp === "string") {
//     temp = removeCurrencyFormatting(amount);
//   }
//   decimalCount = Math.abs(decimalCount);
//   decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

//   const negativeSign = temp < 0 ? "-" : "";

//   let i = parseInt(
//     (amount = Math.abs(Number(temp) || 0).toFixed(decimalCount))
//   ).toString();
//   let j = i.length > 3 ? i.length % 3 : 0;

//   return (
//     negativeSign +
//     "$" +
//     (j ? i.substr(0, j) + thousands : "") +
//     i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
//     (decimalCount
//       ? decimal +
//         Math.abs(amount - i)
//           .toFixed(decimalCount)
//           .slice(2)
//       : "")
//   );
// };

export const formatMoney = (number) => {
  if (!number) return "$0.00";
  let temp = number;
  if (typeof temp === "string") {
    temp = removeCurrencyFormatting(number);
  }

  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(temp);
};

export const formatPrecision = (number, places) => {
  const _places = (typeof places == 'undefined' ? 2 : places);
  const multiplier = Number(`1${`0`.repeat(_places)}`);
  return Math.round((Number(number) + Number.EPSILON) * multiplier) / multiplier;
};

export const formatMoneyToNumber = (str) => {
  const num = Number(str.replace("$", "").replace(",", ""));
  if(!isNaN(num)){
    return num;
  }
  return 0;
};

export const tokenConfigUnconnected = (auth) => {
  if (!auth) return {};
  const token = auth.token;
  const csrf = auth.csrf;
  const config = {
    withCredentials: true,
    headers: {
      "X-CSRF-Token": csrf,
      "Content-Type": "application/hal+json",
      Authorization: `Bearer ${token}`,
    },
    params: {
      _format: "hal_json",
    },
  };

  return config;
};

export const getRoleType = (roles) => {
  const phxClientRoles = [
    "admin",
    "phx_sub_admin",
    "phx_client_admin",
    "operations",
    "accounting",
    "compliance_admin",
  ];

  const memberRoles = [
    "member_admin_agent",
    "member_estimator",
    "member_field_agent",
  ];

  const agentRoles = [
    "mby_agent",
    "mby_agent_admin",
  ];

  const customerRoles = ['customer'];

  if (compareArrays(roles, phxClientRoles)) {
    return "phx_client";
  }

  if (compareArrays(roles, memberRoles)) {
    return "member";
  }

  if (compareArrays(roles, agentRoles)) {
    return "agent";
  }

  if (compareArrays(roles, customerRoles)) {
    return "customer";
  }

  return "view";
};

export const getVisibilityPriority = () => {
  return [
    "admin",
    "phx_client",
    "member",
    "agent",
    "mby_agent",
    "customer",
  ]
};

/**
 * Check the permissions prority of the the role
 * 0 = same priority
 * 1 = higher priority
 * -1 = lower priority
 *
 * @param {*} userRoleType
 * @param {*} checkRoleType
 * @returns
 */
export const checkVisibilityPriority = (userRoleType, checkRoleType) => {
  const visPrty = getVisibilityPriority();
  const userIndex = visPrty.indexOf(userRoleType);
  const checkIndex = visPrty.indexOf(checkRoleType);

  if(userIndex === checkIndex){
    return 0;
  }
  else if(userIndex < checkIndex){
    return 1;
  }
  else if(userIndex > checkIndex){
    return -1;
  }
};

export const isDivisionStatusPast = (division, divisionStatuses, status) => {
  return Number(divisionStatuses[division?.field_jd_status?.tid]?.weight) >= Number(Object.values(divisionStatuses).find((s) => s.machine_name === status)?.weight)
}

export const formatError = (error) => {
  if (error.response) {
    return {
      msg: error.response.data.message,
      status: error.response.status,
      id: "negative",
    };
  } else if (error.request) {
    return { msg: error.request.data, status: 400, id: "negative" };
  } else {
    return { msg: error.message, status: 400, id: "negative" };
  }
};

export const formatErrorResponseString = (error) => {
  if (!error) return "The system encountered an error.";
  const lines = error.split("\n");
  const messages = [];
  lines.forEach((line) => {
    const message = line.split(":");
    messages.push(message[1]);
  });

  return messages.join("\n");
};

export const formatCoords = (coords) => {
  if (!coords) return { lat: null, lng: null };
  const position = coords.split(",");
  return {
    lat: parseFloat(position[0]),
    lng: parseFloat(position[1]),
  };
};

export const arrayIntersections = (arr1 = [], arr2 = []) => {
  return arr1.filter((e) => arr2.find((e2) => String(e) === String(e2)));
};

export const compareArrays = (arr1 = [], arr2 = []) => {
  const intersections = arrayIntersections(arr1, arr2);

  if (intersections.length > 0) return true;
  return false;
};

export const arraysMatch = (arr1 = [], arr2 = [], index) => {
  const intersections1 = arr1.filter((e) =>
    arr2.find((e2) => e === e2)
  );
  const intersections2 = arr2.filter((e) =>
    arr1.find((e2) => e === e2)
  );
  if (intersections1.length === arr2.length && intersections2.length === arr1.length) return true;
  return false;
};

export const arraysMatchNested = (arr1 = [], arr2 = [], index) => {
  const intersections1 = arr1.filter((e) =>
    arr2.find((e2) => e[index] === e2[index])
  );
  const intersections2 = arr2.filter((e) =>
    arr1.find((e2) => e[index] === e2[index])
  );
  if (intersections1.length === arr2.length && intersections2.length === arr1.length) return true;
  return false;
};

export const divideNestedArray = (arr, index) => {
  const divided = {};
  if(!arr) return divided;
  arr.forEach((e) => {
    if(!divided?.[e[index]]){
      divided[e[index]] = [];
    }
    divided[e[index]].push(e);
  });

  return divided;
};

export const hasPermission = (userRoles, intendedRoles) => {
  return compareArrays(userRoles, intendedRoles);
};

export const b64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

export const formatOfficeAddress = (data) => {
  if (!data.officeAddress) {
    return {
      "office-street": "",
      "office-street2": "",
      "office-city": "",
      "office-state": "",
      "office-zip": "",
    };
  }

  return {
    "office-street": data.officeAddress.address_line1,
    "office-street2": data.officeAddress.address_line2
      ? data.officeAddress.address_line2
      : "",
    "office-city": data.officeAddress.locality,
    "office-state": data.officeAddress.administrative_area,
    "office-zip": data.officeAddress.postal_code,
  };
};

export const formatBillingAddress = (data) => {
  if (!data.billingAddress) {
    return {
      "mail-street": "",
      "mail-street2": "",
      "mail-city": "",
      "mail-state": "",
      "mail-zip": "",
    };
  }

  return {
    "mail-street": data.billingAddress.address_line1,
    "mail-street2": data.billingAddress.address_line2
      ? data.billingAddress.address_line2
      : "",
    "mail-city": data.billingAddress.locality,
    "mail-state": data.billingAddress.administrative_area,
    "mail-zip": data.billingAddress.postal_code,
  };
};

export const formatMultipleUsers = (users) => {
  const data = [];
  if (!users) return data;
  users.forEach((user) => {
    data.push({ uid: user.uid, name: user.name });
  });
  return data;
};

export const removeDuplicates = (arr, field) => {
  return arr.filter(
    (elem, index, self) =>
      self.findIndex((t) => {
        return t[field] === elem[field];
      }) === index
  );
};

export const cancelRequest = (source) => {
  if (source) {
    source.cancel("canceled axios request");
  }
};

export const formatFilters = (filters, prefix) => {
  if(!prefix){
    prefix = 'filter'
  }
  const filterSettings = {};
  for (let type in filters) {
    if(type === 'OR' && isObject(filters[type])){
      Object.assign(filterSettings, formatFilters(filters[type], 'filter[OR]'));
    }
    else if (Array.isArray(filters[type]) && filters[type] && filters[type].length) {
      filters[type].forEach((filter, index) => {
        let _index = `${prefix}[${type}][${index}]`;
        let vals = filter;
        if(isObject(filter)){
          if(filter?.value){
            vals = filter.value
          }
          else{
            Object.entries(filter).forEach((_entry) => {
              const [_subIndex, entry] = _entry;
              _index += `[${_subIndex}]`;
              vals = entry.value;
            });
          }
        }
        filterSettings[_index] = vals;
      });
    } else if (filters[type] && (filters[type].type === "date-range" || filters[type].type === "date-range-string")) {
      const values = filters[type].value;
      if (values.from && values.through) {
        let operator = "DATE BETWEEN";
        if(filters[type].type === "date-range-string"){
          operator = "BETWEEN";
        }
        filterSettings[`${prefix}[${type}][0][${operator}][0]`] = moment(
          values.from
        ).format("YYYY-MM-DD");
        filterSettings[`${prefix}[${type}][0][${operator}][1]`] = moment(
          values.through
        ).format("YYYY-MM-DD");
      }
    } else if (filters[type] && (filters[type].type === "date-range-field-start" || filters[type].type === "date-range-field-end")) {
      const operator = (filters[type].type === "date-range-field-start" ? ">=" : "<=");
      filterSettings[`${prefix}[${type}][0][${operator}]`] = moment(filters[type].value).format("YYYY-MM-DD");
    } else if (filters[type] && filters[type].type === "omit") {
      const values = filters[type].value;
      values.forEach((filter, index) => {
        filterSettings[`${prefix}[${filters[type].label}][${index}][!=]`] =
          filter.value;
      });
    } else if (filters[type] && filters[type].value) {
      filterSettings[`${prefix}[${type}]`] = filters[type].value;
    }
  }

  return filterSettings;
};

export const formatProximitySearch = (proximitySearch) => {
  const proximitySettings = {};
  for (let type in proximitySearch) {
    proximitySettings[`proximity_search[${type}]`] = proximitySearch[type];
  }
  return proximitySettings;
};

export const removeColumns = (removedColumns, columns) => {
  removedColumns.forEach((column) => {
    const index = columns.findIndex((c) => c.index === column);
    if (index > -1) {
      columns.splice(index, 1);
    }
  });
};

export const removePathFromUrl = (url, path, removeRemaining = false) => {
  const arr = url.split("/");
  const index = arr.indexOf(path);
  if (removeRemaining && index > 0) {
    arr.splice(index, arr.length - (index - 1));
  } else if (index > 0) {
    arr.splice(index, 1);
  }

  return arr.join("/");
};

export const times = [
  { value: "00:00", name: "12:00 AM" },
  { value: "00:15", name: "12:15 AM" },
  { value: "00:30", name: "12:30 AM" },
  { value: "00:45", name: "12:45 AM" },
  { value: "01:00", name: "1:00 AM" },
  { value: "01:15", name: "1:15 AM" },
  { value: "01:30", name: "1:30 AM" },
  { value: "01:45", name: "1:45 AM" },
  { value: "02:00", name: "2:00 AM" },
  { value: "02:15", name: "2:15 AM" },
  { value: "02:30", name: "2:30 AM" },
  { value: "02:45", name: "2:45 AM" },
  { value: "03:00", name: "3:00 AM" },
  { value: "03:15", name: "3:15 AM" },
  { value: "03:30", name: "3:30 AM" },
  { value: "03:45", name: "3:45 AM" },
  { value: "04:00", name: "4:00 AM" },
  { value: "04:15", name: "4:15 AM" },
  { value: "04:30", name: "4:30 AM" },
  { value: "04:45", name: "4:45 AM" },
  { value: "05:00", name: "5:00 AM" },
  { value: "05:15", name: "5:15 AM" },
  { value: "05:30", name: "5:30 AM" },
  { value: "05:45", name: "5:45 AM" },
  { value: "06:00", name: "6:00 AM" },
  { value: "06:15", name: "6:15 AM" },
  { value: "06:30", name: "6:30 AM" },
  { value: "06:45", name: "6:45 AM" },
  { value: "07:00", name: "7:00 AM" },
  { value: "07:15", name: "7:15 AM" },
  { value: "07:30", name: "7:30 AM" },
  { value: "07:45", name: "7:45 AM" },
  { value: "08:00", name: "8:00 AM" },
  { value: "08:15", name: "8:15 AM" },
  { value: "08:30", name: "8:30 AM" },
  { value: "08:45", name: "8:45 AM" },
  { value: "09:00", name: "9:00 AM" },
  { value: "09:15", name: "9:15 AM" },
  { value: "09:30", name: "9:30 AM" },
  { value: "09:45", name: "9:45 AM" },
  { value: "10:00", name: "10:00 AM" },
  { value: "10:15", name: "10:15 AM" },
  { value: "10:30", name: "10:30 AM" },
  { value: "10:45", name: "10:45 AM" },
  { value: "11:00", name: "11:00 AM" },
  { value: "11:15", name: "11:15 AM" },
  { value: "11:30", name: "11:30 AM" },
  { value: "11:45", name: "11:45 AM" },
  { value: "12:00", name: "12:00 PM" },
  { value: "12:15", name: "12:15 PM" },
  { value: "12:30", name: "12:30 PM" },
  { value: "12:45", name: "12:45 PM" },
  { value: "13:00", name: "1:00 PM" },
  { value: "13:15", name: "1:15 PM" },
  { value: "13:30", name: "1:30 PM" },
  { value: "13:45", name: "1:45 PM" },
  { value: "14:00", name: "2:00 PM" },
  { value: "14:15", name: "2:15 PM" },
  { value: "14:30", name: "2:30 PM" },
  { value: "14:45", name: "2:45 PM" },
  { value: "15:00", name: "3:00 PM" },
  { value: "15:15", name: "3:15 PM" },
  { value: "15:30", name: "3:30 PM" },
  { value: "15:45", name: "3:45 PM" },
  { value: "16:00", name: "4:00 PM" },
  { value: "16:15", name: "4:15 PM" },
  { value: "16:30", name: "4:30 PM" },
  { value: "16:45", name: "4:45 PM" },
  { value: "17:00", name: "5:00 PM" },
  { value: "17:15", name: "5:15 PM" },
  { value: "17:30", name: "5:30 PM" },
  { value: "17:45", name: "5:45 PM" },
  { value: "18:00", name: "6:00 PM" },
  { value: "18:15", name: "6:15 PM" },
  { value: "18:30", name: "6:30 PM" },
  { value: "18:45", name: "6:45 PM" },
  { value: "19:00", name: "7:00 PM" },
  { value: "19:15", name: "7:15 PM" },
  { value: "19:30", name: "7:30 PM" },
  { value: "19:45", name: "7:45 PM" },
  { value: "20:00", name: "8:00 PM" },
  { value: "20:15", name: "8:15 PM" },
  { value: "20:30", name: "8:30 PM" },
  { value: "20:45", name: "8:45 PM" },
  { value: "21:00", name: "9:00 PM" },
  { value: "21:15", name: "9:15 PM" },
  { value: "21:30", name: "9:30 PM" },
  { value: "21:45", name: "9:45 PM" },
  { value: "22:00", name: "10:00 PM" },
  { value: "22:15", name: "10:15 PM" },
  { value: "22:30", name: "10:30 PM" },
  { value: "22:45", name: "10:45 PM" },
  { value: "23:00", name: "11:00 PM" },
  { value: "23:15", name: "11:15 PM" },
  { value: "23:30", name: "11:30 PM" },
  { value: "23:45", name: "11:45 PM" },
];

export const replaceErrorResponseString = (msg, fields) => {
  if (msg && Array.isArray(fields)) {
    let newMessage = msg;

    for (let i in fields) {
      if (i === 0) {
        newMessage = `${newMessage.replace(fields[i].field, fields[i].label)}`;
      } else {
        newMessage = `${newMessage.replace(
          fields[i].field,
          `<br/>${fields[i].label}`
        )}`;
      }
    }

    return newMessage;
  } else {
    return msg;
  }
};

export const removeFileExtensionFromString = (s) => {
  if (typeof s === "string") {
    return s.replace(/\.[^/.]+$/, "");
  } else {
    return s;
  }
};

export const formatInsuranceAgent = (d) => {
  return {
    nid: formatField(d, "nid"),
    title: formatField(d, "title"),
    email: formatField(d, "field_member_ins_agent_email"),
    phone: formatField(d, "field_member_ins_agent_phone"),
    agency: formatField(d, "field_member_ins_agent_agcy_name"),
    categories: d.field_coverage_categories
      ? d.field_coverage_categories.map((category) => ({
          tid: formatField(category, "tid"),
          name: formatField(category, "name"),
        }))
      : [],
  };
};

export const generateTempUUID = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

/**
 * Remove any non-numeric characters from a string
 *
 * @returns string
 */
export const cleanNumberInput = (string) => {
  if(string && typeof string === 'string'){
    let output =  string.replace(/[^0-9.]/g, '');
  //  Round-up to 2 decimal places, but wait until it needs to.
    if(output.indexOf('.') > -1 && output.indexOf('.') < output.length - 3){
      output = String(parseFloat(Number(output).toFixed(2)));
    }
    return output;
  }
  return string;
};

/**
 * Move an item in an array
 *
 * @param array list
 * @param int startIndex
 * @param int endIndex
 * @returns array
 */
export const moveArrayItem = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

/**
 * Split a string that was created by GROUP_CONCAT with escaped commas
 *
 * @param string text
 * @returns array
 */
export const splitEscapedCommaString = (text) => {
  if(!text) return [];
  const parsed = text.replace(/\\,/g, '|||');
  const rawArray = parsed.split(',');
  const result = rawArray.map((item) => item.replace(/\|\|\|/g, ','));

  return result;
};

export const capitalizeWords = (str, separator) => {
  const sep = separator || ' ';
  const string = str || '';
  return string
    .toLowerCase()
    .split(sep)
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};


export const laravelModelAsType = (str) => {
  if(!str){
    return '';
  }
  const _parts = str.split('\\')
  return _parts[_parts.length - 1].toLowerCase();
}

export const simpleBrowserInfo = () => {
  const sUsrAg = navigator.userAgent;
  let type = null;
  let version = null;
  if (sUsrAg.indexOf("Firefox") > -1) {
    version = sUsrAg.match(/Firefox\/(\d+\.\d+)/);
    // ex. "Mozilla/5.0 (Macintosh; Intel Mac OS X 10.15; rv:122.0) Gecko/20100101 Firefox/122.0"
    type = "firefox";
  }
  else if (sUsrAg.indexOf("Safari") > -1 && sUsrAg.indexOf("Chrome") === -1) {
    version = sUsrAg.match(/Version\/(\d+\.\d+\.\d+)/);
    if(!version){
      version = sUsrAg.match(/Version\/(\d+\.\d+)/);
    }
    // "ex. Mozilla/5.0 (iPhone; CPU iPhone OS 11_4 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/11.0 Mobile/15E148 Safari/604.1 980x1306"
    type = "safari";
  }
  else if (sUsrAg.indexOf("Chrome") > -1) {
    version = sUsrAg.match(/Chrome\/(\d+\.\d+\.\d+)/);
    if(!version){
      version = sUsrAg.match(/Chrome\/(\d+\.\d+)/);
    }
    // "ex. Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/121.0.0.0 Safari/537.36"
    type = "chrome";
  }

  version = version[1];

  return {
    type,
    version,
    versionParts: version.split('.').map(v => parseInt(v)),
  };
};

export const pxNum = (str) => {
  return Number(str.replace(/px/, ''));
};

export const validateEmail = (email) => {
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line no-useless-escape
  return re.test(String(email).toLowerCase());
};

export const getWeek = (_weekStart, date) => {
  const phpStart = Number(_weekStart);
  const weekStart = phpStart === 7 ? 0 : phpStart;
  moment.updateLocale('en', {
    week : {
        dow : weekStart, // First day of week
        doy : (7 + weekStart -1) // First week of year must contain 1 January (7 + weekStart - 1)
    }
  });

  return {
    weekStart: moment(date).locale('en').clone().startOf("week").format("YYYY-MM-DD"),
    weekEnd: moment(date).locale('en').clone().endOf("week").format("YYYY-MM-DD"),
  };
};
