import { Button, Dialog, DialogActions } from '@mui/material'
import { DateFormField, SignatureFormField, TextFormField } from 'common/Fields'
import React, { useState } from 'react'

const PDFFormField = ({ edit, value, onChange, onBlur, zoom }) => {
    const determineInput = (type) => {
        switch(type) {
            case 'date':
                return <DateFormField
                    format="MM/DD/YYYY"
                    label={edit.text}
                    required={true} 
                    name={edit.form_field_id}
                    inputVariant="filled"
                    KeyboardButtonProps={{
                    "aria-label": "change date",
                    }}
                    style={{ height: edit.height*zoom, width: edit.width*zoom, resize: 'none' }}
                />
            case 'text':
                return (<TextFormField
                    variant="filled"
                    name={edit.form_field_id}
                    label={edit.text}
                    multiline={false}
                    required={true} 
                    onChange={onChange} 
                    onBlur={onBlur}
                    style={{ height: edit.height*zoom, width: edit.width*zoom, resize: 'none' }} />)
            case 'textbox' :
                return (
                    <div style={{ height: edit.height*zoom, width: edit.width*zoom }} >
                        <TextFormField
                            variant="filled"
                            name={edit.form_field_id}
                            label={edit.text}
                            maxRows={Math.round(edit.height/(edit.size*1.6))}
                            multiline={true}
                            required={true} 
                            onChange={onChange} 
                            onBlur={onBlur}
                        />
                    </div>
                )
            case 'signature' :
                return <div style={{ height: edit.height*zoom, width: edit.width*zoom, /*fontSize: edit.size,*/ }}><Signature name={edit.form_field_id} value={value}/> </div>
            default :
                return ''
        }
    }
    return determineInput(edit.type)
}

const Signature = ({name, value}) => {
    const [open, setOpen] = useState()

    return (<>
        <Button
            onClick={() => setOpen(true)}
            variant="contained"
            disableElevation
            style={{
                height: '100%',
                width: '100%',
                color: "white",
            }}
        >
            Digital Signature
        </Button>
        <Dialog
            open={open}
            maxWidth="xs"
            onClose={() => setOpen(false)}
            title="Signature"
            keepMounted
        >
            <div style={{ padding: "1rem" }}>
                <SignatureFormField name={name} value={value} />
            </div>
            <DialogActions>
                <Button onClick={() => setOpen(false)}>Done</Button>
            </DialogActions>
        </Dialog></>)
}

export default PDFFormField