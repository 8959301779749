import React, {useContext} from "react";
import PropTypes from "prop-types";
import hoistStatics from "hoist-non-react-statics";
import { getRoleType } from "../utility";

const formatRoles = (data) => {
  const roles = [];
  if (!data || !Array.isArray(data)) return roles;
  return data;
};

export const PermissionsProviderContext = React.createContext({
  permissions: {},
  roles: [],
  roleType: '',
})

class PermissionsProvider extends React.Component {
  // static propTypes = {
  //   permissions: PropTypes.array.isRequired,
  // };

  // static contextTypes = {
  //   permissions: PropTypes.array,
  // };

  // static childContextTypes = {
  //   permissions: PropTypes.array.isRequired,
  //   roles: PropTypes.array.isRequired,
  //   roleType: PropTypes.string.isRequired,
  // };

  // getChildContext() {
  //   const { permissions } = this.props;
  //   const roles = formatRoles(permissions);
  //   const roleType = getRoleType(roles);

  //   return { permissions, roles, roleType };
  // }

  render() {
    // return React.Children.only(this.props.children);
    const { permissions } = this.props;
    const roles = formatRoles(permissions);
    const roleType = getRoleType(roles);

    return (
      <PermissionsProviderContext.Provider
        value={{
          permissions: permissions,
          roles: roles,
          roleType: roleType,
        }}
      >
        {this.props.children}
      </PermissionsProviderContext.Provider>
    );
  }
}

const withPermissions = (Component) => {
  const C = (props) => {
    const context = useContext(PermissionsProviderContext);
    const { wrappedComponentRef, ...remainingProps } = props;

    return (
      <Component
        permissions={context.permissions}
        roles={context.roles}
        roleType={context.roleType}
        {...remainingProps}
        ref={wrappedComponentRef}
      />
    );
  };

  C.displayName = `withPermissions(${Component.displayName || Component.name})`;
  C.WrappedComponent = Component;
  C.propTypes = {
    wrappedComponentRef: PropTypes.func,
  };

  // C.contextTypes = {
  //   permissions: PropTypes.array.isRequired,
  //   roles: PropTypes.array.isRequired,
  //   roleType: PropTypes.string.isRequired,
  // };

  return hoistStatics(C, Component);
};

export { PermissionsProvider as default, withPermissions };
