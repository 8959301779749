import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Tooltip,
  IconButton,
} from "@mui/material";
import Indicators from "common/Indicators";
import { TimeClockDialog } from "components/TimeClock";
import { ClockIcon } from "common/ClockIcon";
import { refreshUserTimeCards, } from "components/TimeClock/timeClockSlice";


const TimeClockIndicator = () => {
  const dispatch = useDispatch();
  const _processed = useSelector((state) => state.auth.user.data._processed);
  // const timeCards = useSelector(timeCardsSelectors.selectAll);

  if(!_processed || !_processed?.open_time_card){
    return (<></>);
  }

  const openCard = _processed.open_time_card;

  return (
    <TimeClockDialog
      divisionHash={openCard.division_hash}
      serviceHash={openCard.service_hash}
      onClose={() => dispatch(refreshUserTimeCards())}
    >
      <Tooltip title="View Time Card">
        <IconButton
          size="large"
          // className="non-mui-icon"
          style={{ color: "white" }}
        >
          <Indicators
            orange={true}
            // marginRightStart={0.125}
          >
            <ClockIcon run={true} />
          </Indicators>
        </IconButton>
      </Tooltip>
    </TimeClockDialog>
  );
};

TimeClockIndicator.propTypes = {};

export default TimeClockIndicator;
