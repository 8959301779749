import axios from "axios";
import conf from "config";

const baseUrl = conf.helix_api_url;
const instance = axios.create({
  baseURL: `${baseUrl}/api`,
});

const API = {
  updateEdit: async ({ id, params, config }) => {
    return instance.patch(`/form-edits/${id}`, params, config);
  },
  createEdit: async ({ params, config }) => {
    return instance.post(`/form-edits`, params, config);
  },
  deleteEdit: async ({ id, params, config }) => {
    return instance.delete(`/form-edits/${id}`, config);
  },
  patchEdits: async ({ params, config }) => {
    return instance.patch(`/form-edits`, params, config);
  },
  getEdits: async ({ id, config }) => {
    return instance.get(`/form-edits/${id}`, config)
  },
  uploadAttachment: async ({ id, params, config }) => {
    return instance.patch(`/forms/upload/${id}`, params, config);
  },
  getAttachment: async ({ id, config }) => {
    return instance.get(`/attachments/${id}/url`, config);
  },
  downloadAttachment: async ({ id, config }) => {
    return instance.get(`/attachments/${id}`, config);
  },
  deleteAttachment: async ({ id, config }) => {
    return instance.delete(`/attachments/${id}`, config);
  },
};

export default API;
